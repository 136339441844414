<!--
 * @Author: chenxing
 * @Date: 2021-04-08 11:05:08
 * @LastEditors: chenxing
 * @LastEditTime: 2022-02-21 13:17:19
-->
<template>
  <div @click.stop @click.self="closeModal" class="material-library video-library">
    <a-config-provider :locale="locale">
      <div class="filter-container flexJustifyBetween" @click.self="closeModal" ref="filter">
        <a-form class="filter-content w100" ref="filterForm" :rules="filterRules" :model="filterCondition">
          <a-row @click.self="closeModal">
            <!-- 素材ID、素材名称 -->
            <a-col :span="6">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-input-group compact>
                  <a-select v-model:value="filterType" style="width: 87px; min-width: auto"
                            @change="() => {filterCondition.fileName = undefined;filterCondition.materialIds = undefined;}">
                    <a-select-option value="materialIds">素材ID</a-select-option>
                    <a-select-option value="fileName">素材名称</a-select-option>
                  </a-select>
                  <a-input v-model:value="filterCondition.materialIds" placeholder="素材ID" allow-clear
                           @keydown="onInputKeyDown" :disabled="isDisabled(filterCondition.materialIdList)"
                           style="width: calc(100% - 86px); height: 32px" v-if="filterType == 'materialIds'" />
                  <a-auto-complete style="width: calc(100% - 86px)" v-else v-model:value="filterCondition.fileName"
                                   :dropdown-match-select-width="false" :options="materialNameOptions" allow-clear
                                   option-label-prop="value" @search="getMaterialNameList"
                                   :disabled="isDisabled(filterCondition.materialIdList)">
                    <template #default>
                      <a-input v-model:value="filterCondition.fileName" placeholder="素材名称"
                               @keydown.enter="autoComplateEnter"
                               :disabled="isDisabled(filterCondition.materialIdList)"></a-input>
                    </template>
                    <template #notFoundContent>
                      <a-empty :image="simpleImage">
                        <template #description>
                          <span>暂无数据</span>
                        </template>
                      </a-empty>
                    </template>
                  </a-auto-complete>
                </a-input-group>
              </a-form-item>
            </a-col>
            <!-- 关联产品 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.projectCodeList"
                                :select-data="filterCondition.projectCodeList" class="select-tagmode"
                                :show-rectangle="true" :select-options="projectOptions" :label-in-value="true"
                                placeholder="关联产品" v-bind="$multipleFilterOptions" select-option-value="projectCode"
                                select-option-label="projectName"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>
            <!-- 上传部门 -->
            <a-col :span="3" v-show="isMatch=='Y'">
              <a-form-item :wrapper-col="{ span: 24 }">
                <!--                <SelectMultiple v-model:value="filterCondition.deptIdList" :select-data="filterCondition.deptIdList" :select-options="filterDepIdListAll" :label-in-value="true" placeholder="上传部门" v-bind="$multipleFilterOptions" select-option-value="deptId" select-option-label="name" @change="getUserList" :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />-->
                <a-tree-select class="select-tagmode" tree-checkable :max-tag-count="1"
                               :show-checked-strategy="SHOW_ALL" v-model:value="filterCondition.deptIdList"
                               :tree-data="filterDepIdListAll" :replace-fields="replaceFields" placeholder="上传部门"
                               style="width: 100%" tree-node-filter-prop="title"
                               :tree-default-expanded-keys="treeDefaultExpandedKeys" allow-clear show-search
                               :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)"></a-tree-select>
              </a-form-item>
            </a-col>
            <!-- 所属行业 -->
            <a-col :span="3" v-show="isMatch=='Y'">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-tree-select class="select-tagmode" tree-checkable :max-tag-count="1"
                               :show-checked-strategy="SHOW_ALL" v-model:value="filterCondition.industryList"
                               :tree-data="IndustryOptions" :replace-fields="replaceIndustryFields"
                               placeholder="所属行业" style="width: 100%" tree-node-filter-prop="title"
                               :tree-default-expanded-keys="treeDefaultExpandedKeys" allow-clear show-search
                               :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)"></a-tree-select>
              </a-form-item>
            </a-col>
            <!-- 后期 -->
            <a-col :span="3" v-show="isMatch=='Y'">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.userIdList" placeholder="后期"
                                :select-data="filterCondition.userIdList" :show-rectangle="true"
                                :select-options="unloadPersonList" :label-in-value="true"
                                v-bind="$multipleFilterOptions" select-option-value="userId"
                                select-option-label="userCode"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>
            <!-- 上传时间 -->
            <a-col :span="6" v-show="isMatch=='Y'">
              <a-form-item :wrapper-col="{ span: 24 }" class="mb0 no-flex">
                <a-range-picker class="w100" :placeholder="['上传开始日期', '上传结束日期']" separator="到"
                                v-model:value="filterCondition.uploadTimeValue" :value-format="dateRangeFormat"
                                :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges"
                                :disabled-date="disabledDate" @change="onDateUploadRangeChange"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                  <template #suffixIcon>
                    <CalendarTwoTone />
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row @click.self="closeModal" v-show="!foldFilter && isMatch=='Y'">
            <!-- 使用部门 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.useDeptIds" :select-data="filterCondition.useDeptIds"
                                :select-options="filterDepIdList" :label-in-value="true" placeholder="使用部门"
                                v-bind="$multipleFilterOptions" select-option-value="deptId" select-option-label="name"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
                <!-- <a-tree-select class="select-tagmode w100" tree-checkable :max-tag-count="1" :show-checked-strategy="SHOW_ALL" v-model:value="filterCondition.useDeptIds" :tree-data="departmentOptions" :replace-fields="replaceFields" placeholder="使用部门" label-in-value style="width: 100%" tree-node-filter-prop="title" :tree-default-expanded-keys="treeDefaultExpandedKeys" allow-clear show-search :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" :max-tag-placeholder="maxTagPlaceholder"> </a-tree-select> -->
              </a-form-item>
            </a-col>
            <!-- 投放状态 -->
            <a-col :span="2">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select placeholder="投放状态" mode="multiple" v-model:value="filterCondition.costStatus" allow-clear
                          :show-arrow="false" :filter-option="true" option-filter-prop="label"
                          :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                  <a-select-option v-for="option in consumptionStatusOptions" :key="option.value" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- 拒审状态 -->
            <a-col :span="2">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select :options="rejectTagOptions" placeholder="拒审状态" v-model:value="filterCondition.rejectTag"
                          allow-clear :show-arrow="true" :filter-option="true" option-filter-prop="label"
                          :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)"></a-select>
              </a-form-item>
            </a-col>
            <!-- 素材类型 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterCondition.materialChannel" placeholder="素材类型" mode="multiple"
                          allow-clear
                          :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                  <a-select-opt-group label="内部">
                    <a-select-option key="QJJ" value="QJJ">情景</a-select-option>
                    <a-select-option key="QJJ_KB" value="QJJ_KB">情景-口播</a-select-option>
                    <a-select-option key="QJJ_ZH" value="QJJ_ZH">情景-置换</a-select-option>
                    <a-select-option key="GM_QJJ_AE" value="GM_QJJ_AE">游戏情景AE</a-select-option>
                    <a-select-option key="GM_QT_AE" value="GM_QT_AE">游戏前贴AE</a-select-option>
                    <!--                  <a-select-option key="GJSC" value="GJSC">共建素材</a-select-option>-->
                    <!--                  <a-select-option key="GJ_TP_10" value="GJ_TP_10">共建-套皮10分钟</a-select-option>-->
                    <a-select-option key="YSJJ" value="YSJJ">剪辑</a-select-option>
                    <a-select-option key="YSJJ_2X" value="YSJJ_2X">剪辑*2</a-select-option>
                    <a-select-option key="YSJJ_3X" value="YSJJ_3X">剪辑*3</a-select-option>
                    <a-select-option key="YSJJ_4X" value="YSJJ_4X">剪辑*4</a-select-option>
                    <a-select-option key="YSJJ_7X" value="YSJJ_7X">剪辑*7</a-select-option>
                    <!--                  <a-select-option key="EC" value="EC">二创剪辑</a-select-option>-->
                    <a-select-option key="HJ" value="HJ">剪辑-混剪</a-select-option>
                    <a-select-option key="AIJJ" value="AIJJ">剪辑-Ai</a-select-option>
                    <!--                  <a-select-option key="AIJJ_KG" value="AIJJ_KG">AI剪辑(客供)</a-select-option>-->
                    <a-select-option key="TP_QJJ" value="TP_QJJ">情景-1/2</a-select-option>
                    <!--                  <a-select-option key="TP_HQ_PY" value="TP_HQ_PY">套皮-后期-配音</a-select-option>-->
                    <a-select-option key="TP_HQ_JZ" value="TP_HQ_JZ">情景-混剪</a-select-option>
                    <!--                  <a-select-option key="TP_HQ_QT" value="TP_HQ_QT">套皮-后期-前贴</a-select-option>-->
                    <!--                  <a-select-option key="TP_HQ_5" value="TP_HQ_5">套皮-后期-5分钟</a-select-option>-->
                    <a-select-option key="TP_HQ_15" value="TP_HQ_15">剪辑-1/5</a-select-option>
                    <a-select-option key="TP_HQ_30" value="TP_HQ_30">剪辑-1/2</a-select-option>
                    <a-select-option key="TP_EC" value="TP_EC">情景-二改</a-select-option>
                    <a-select-option key="TP_EC_WC" value="TP_EC_WC">情景-二改-外采</a-select-option>
                    <a-select-option key="TP_HJ_WC" value="TP_HJ_WC">情景-混剪-外采</a-select-option>
                    <a-select-option key="TP_EC_WB" value="TP_EC_WB">情景-二改-外部</a-select-option>
                    <a-select-option key="TP_HJ_WB" value="TP_HJ_WB">情景-混剪-外部</a-select-option>

                    <!-- <a-select-option key="ECQJJ" value="ECQJJ">二创情景剧</a-select-option> -->
                  </a-select-opt-group>
                  <a-select-opt-group label="外部">
                    <a-select-option key="CG" value="CG">采购</a-select-option>
                    <a-select-option key="KHTG" value="KHTG">客户提供</a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-item>
            </a-col>

            <!-- 情景类别 -->
            <a-col :span="2">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select v-model:value="filterCondition.sitcomType" placeholder="情景类别" allow-clear
                          :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                  <a-select-option v-for="option in sitcomTypeList" :key="option.label" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <!-- 素材方向 -->
            <a-col :span="2">
              <a-form-item :wrapper-col="{ span: 24 }" name="direction" class="mb0">
                <a-select v-model:value="filterCondition.direction" placeholder="素材方向" allow-clear
                          :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                  <a-select-option key="HORIZONTAL" value="HORIZONTAL">横向</a-select-option>
                  <a-select-option key="VERTICAL" value="VERTICAL">竖向</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <!-- 摄像 -->
            <!--            <a-col :span="2">
                          <a-form-item :wrapper-col="{ span: 24 }">
                            <a-select v-model:value="filterCondition.shootingName" placeholder="摄像" show-search allow-clear :filter-option="true" option-filter-prop="label" :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                              <a-select-option v-for="option in phtographerList" :key="option.label" :value="option.label" :label="option.label">{{ option.label }}</a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>-->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.shootingNames" placeholder="摄像"
                                :select-data="filterCondition.shootingNames" :show-rectangle="true"
                                :select-options="phtographerList" :label-in-value="true" v-bind="$multipleFilterOptions"
                                select-option-value="value" select-option-label="label"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>
            <!-- 编导 -->
            <!--            <a-col :span="2">
                          <a-form-item :wrapper-col="{ span: 24 }">
                            <a-select v-model:value="filterCondition.screenwriterName" placeholder="编导" show-search allow-clear :filter-option="true" option-filter-prop="label" :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                              <a-select-option v-for="option in scenaristList" :key="option.label" :value="option.label" :label="option.label">{{ option.label }}</a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>-->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.screenwriterNames" placeholder="编导"
                                :select-data="filterCondition.screenwriterNames" :show-rectangle="true"
                                :select-options="scenaristList" :label-in-value="true" v-bind="$multipleFilterOptions"
                                select-option-value="value" select-option-label="label"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>
            <!-- 制片 -->
            <!--            <a-col :span="2">
                          <a-form-item :wrapper-col="{ span: 24 }">
                            <a-select v-model:value="filterCondition.producerName" placeholder="制片" show-search allow-clear :filter-option="true" option-filter-prop="label" :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                              <a-select-option v-for="option in filemakerList" :key="option.label" :value="option.label" :label="option.label">{{ option.label }}</a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>-->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.producerNames" placeholder="制片"
                                :select-data="filterCondition.producerNames" :show-rectangle="true"
                                :select-options="filemakerList" :label-in-value="true" v-bind="$multipleFilterOptions"
                                select-option-value="value" select-option-label="label"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row @click.self="closeModal" v-show="!foldFilter && isMatch=='Y'">
            <!-- 演员 -->
            <!-- <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-select :options="actorList" label-in-value v-model:value="filterCondition.actorName" placeholder="演员" show-search allow-clear :filter-option="true" option-filter-prop="label" :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)"> </a-select>
              </a-form-item>
            </a-col> -->
            <!-- 消耗区间 -->
            <a-col :span="6" style="margin-bottom: 8px">
              <a-form-item label="消耗" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" class="mb0 no-flex">
                <div class="flexAlignCenter">
                  <a-input style="width: calc(50% - 8px)" v-model:value="filterCondition.minCostTotal"
                           placeholder="最小值" suffix="元"
                           :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)"
                           @blur="onCostChange"></a-input>
                  <div style="width: 16px; text-align: center">~</div>
                  <a-input style="width: calc(50% - 8px)" v-model:value="filterCondition.maxCostTotal"
                           placeholder="最大值" suffix="元"
                           :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)"
                           @blur="onCostChange"></a-input>
                </div>
              </a-form-item>
            </a-col>
            <!-- 标签 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.labelIds" placeholder="标签"
                                :select-data="filterCondition.labelIds" :show-rectangle="true"
                                :select-options="cascaderOption" v-bind="$multipleFilterOptions"
                                select-option-value="id" select-option-label="name"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>

            <!-- 素材标签标签 -->
            <!--            <a-col :span="4">
                          <a-form-item :wrapper-col="{ span: 24 }">
                            <a-select placeholder="素材标签标签" v-model:value="filterCondition.material_properties" allow-clear :show-arrow="true" :filter-option="true" option-filter-prop="label" :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)">
                              <a-select-option v-for="option in ttStatusOptions" :key="option.value" :value="option.value" :label="option.label">{{ option.label }} </a-select-option>
                            </a-select>
                          </a-form-item>
                        </a-col>-->

            <!-- 素材标签 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.material_properties"
                                :select-data="filterCondition.material_properties" :select-options="ttStatusOptions"
                                :label-in-value="false" placeholder="素材标签" v-bind="$multipleFilterOptions"
                                select-option-label="label" @change="getUserList"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>

            <!-- 素材前测标签 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }">
                <SelectMultiple v-model:value="filterCondition.material_properties_diagnosis"
                                :select-data="filterCondition.material_properties_diagnosis" :select-options="propertiesDiagnosisSelectOptionNames"
                                :label-in-value="false" placeholder="素材前测标签" v-bind="$multipleFilterOptions"
                                select-option-label="label" @change="getUserList"
                                :disabled="isDisabled(filterCondition.materialIds || filterCondition.materialIdList)" />
              </a-form-item>
            </a-col>


          </a-row>
          <a-row @click.self="closeModal">
            <!-- 投放时间 -->
            <a-col :span="6">
              <a-form-item label="投放时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" name="playTimeValue"
                           class="mb0 no-flex">
                <a-range-picker class="w100" :placeholder="['投放开始日期', '投放结束日期']" separator="到"
                                v-model:value="filterCondition.playTimeValue"
                                :format="[dateRangeFormat, dateRangeFormat]" :allow-clear="false" :ranges="dateRanges"
                                :disabled-date="disabledDate" @change="onDatePlayRangeChange" :disabled="tableLoading">
                  <template #suffixIcon>
                    <CalendarTwoTone />
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
            <!-- 渠道 -->
            <a-col :span="3">
              <a-form-item :wrapper-col="{ span: 24 }" class="mb0">
                <a-select placeholder=" 渠道" v-model:value="filterCondition.mediaChannelList" allow-clear
                          :max-tag-count="1" :show-arrow="true" @change="mediaChannelChange" :disabled="tableLoading">
                  <a-select-option value="TT_2">巨量引擎2.0</a-select-option>
                  <a-select-option value="TT">巨量引擎</a-select-option>
                  <a-select-option value="GDT">腾讯广告</a-select-option>
                  <a-select-option value="GDT_V3">腾讯广告3.0</a-select-option>
                  <a-select-option value="KS">磁力引擎</a-select-option>
                  <a-select-option value="QC">巨量千川</a-select-option>
                  <a-select-option value="TTSTAR">巨量星图</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- <a-form-item :wrapper-col="{ span: 24 }">
              <a-select placeholder="请选择素材审核状态" v-model:value="filterCondition.reviewStatus" allow-clear :show-arrow="true" :filter-option="true" option-filter-prop="label">
                <a-select-option value="Abnormal" label="审核异常">审核异常</a-select-option>
              </a-select>
            </a-form-item>
            -->
            <a-col :span="2">
              <a-form-item :wrapper-col="{ span: 24 }" name="match" class="mb0">
                <a-select v-model:value="isMatch" placeholder="是否匹配" allow-clear>
                  <a-select-option value="Y">匹配</a-select-option>
                  <a-select-option value="N">不匹配</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="filter-buttons flexBetweenCenter">
          <a-button class="btn filter-btn" type="primary" @click="onFilterBtnClick" :disabled="tableLoading">筛选
          </a-button>
          <a-button class="btn cancen-btn" @click="onResetBtnClick" :disabled="tableLoading">重置</a-button>
          <div class="filter-fold-icon" :class="{ folded: foldFilter }" @click="foldFilter = !foldFilter">
            {{ foldFilter ? '展开' : '收起' }}
            <DoubleRightOutlined />
          </div>
        </div>
      </div>

      <div class="table-wrapper no_scroll" @click.self="closeModal" style="padding-bottom: 0">
        <div class="table-toolbar flexJustifyBetween" @click.self="closeModal">
          <div>
            <a-space class="toolbar-left" :size="12">
              <a-button v-hasPermission="`MATERIAL_UPLOAD`" type="primary" @click="onAddMaterialBtnClick"
                        :disabled="tableLoading">
                <template #icon>
                  <CloudUploadOutlined />
                </template>
                上传视频
              </a-button>
              <a-button v-hasPermission="`MATERIAL_BATCHPUSH`" :disabled="!isSelectMaterialSameProd || tableLoading"
                        @click="batchPush">批量推送
              </a-button>
              <a-button v-hasPermission="`MATERIAL_BATCHDOWNLOAD`"
                        :disabled="selectedRowKeys.length <= 0 || tableLoading" @click="batchDownload">批量下载
              </a-button>
              <a-button v-hasPermission="`MATERIAL_BATCHUPDATE`" :disabled="selectedRowKeys.length <= 0 || tableLoading"
                        @click="batchUpdate">批量修改
              </a-button>
              <a-button v-hasPermission="`MATERIAL_DOWNLOAD`" :loading="excelExporting" :disabled="tableLoading"
                        @click="onExportExcelBtnClick">
                导出Excel
                <template #icon>
                  <CloudDownloadOutlined />
                </template>
              </a-button>
              <!-- <a-button @click="toAnalysis">数据分析</a-button> -->
            </a-space>
          </div>
          <a-space class="toolbar-right" :size="12">
            <label>显示具体数字：</label>
            <a-switch :disabled="tableLoading" v-model:checked="amountShowDetail" />
            <a-button class="btn filter-btn" type="primary" @click="onFilterBtnClickAndFlush" :disabled="tableLoading">
              刷新
            </a-button>
            <span class="refresh-time">数据更新时间：{{ refreshTime }}</span>
            <!-- <a-button class="refresh-btn" :disabled="tableLoading" type="primary" @click="getMaterialPageListData">
              <template #icon><UndoOutlined /></template>
            </a-button> -->
            <caliber
              v-show="filterCondition.mediaChannelList === 'GDT' || filterCondition.mediaChannelList === 'GDT_V3'"
              style="width: 200px" @selectItem="onTJKJSelect" :disabled="tableLoading"></caliber>
            <TempSelect :temp-list="tempList" :active-temp-name="activeTempName" @getTempName="getTempNameValue"
                        @editTemp="editTemp" @deleteTemp="deleteTemp" @add-temp="addTemp" :disabled="tableLoading" />
          </a-space>
        </div>

        <!-- 已筛选条件 -->
        <div v-show="getFilterOptionsTag().length"
             style="border: 1px solid #f0f0f0; border-bottom: none; padding: 0 9px 9px 9px">
          <SelectedConditions :condition-list="getFilterOptionsTag()" @removeTag="removeTag"></SelectedConditions>
        </div>
        <div id="material-library-table">
          <TableWithStatistic :columns="tableColumns" :data-source="dataSource" :statistic="statistic"
                              :column-width-equal-title="true"
                              :scroll="{ x: scrollWidth, wrapperId: 'material-library-table', interval: 100 }" bordered
                              size="small" class="media-table" ref="videoTable"
                              :row-key="record => record.id + '' + record.mediaChannel + record.ofProjectCode"
                              :loading="tableLoading || tableSetLoading" :pagination="tablePagination"
                              :row-selection="{ getCheckboxProps: getCheckboxProps, selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                              @change="onTableChange" :custom-row="rowClick" :custom-header-row="rowHeaderClick"
                              :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
            <template #default="{ record, text, column }">
              <template v-if="column.dataIndex == 'url'">
                <div v-if="record.url !== '-'" class="flexJustifyStart">
                  <div class="flexAlignBetween position-rel">
                    <video-player v-if="record.showVideo" class="video-player" v-bind="playerOptions"
                                  :play="record.play"
                                  @playStatusChange="status => playStatusChange(status, record)"></video-player>
                    <img class="table-img" :src="getThumbOSSUrl(record)" :alt="record.fileName" style="cursor: pointer"
                         title="点击预览" />
                    <div v-if="!record.showVideo" class="img-hover position-abs flexJustifyCenter flexAlignCenter"
                         @click="preview(record)">
                      <PlayCircleOutlined class="img-hover-play" :style="{ fontSize: '24px', color: '#fff' }" />
                    </div>
                    <div class="align-l pt10">
                      <span class="pr15" style="color: #686868">{{ record.postName }}</span>
                      <span style="color: #686868">{{ record.createTime }}</span>
                      <a-space class="flexJustifyStart" :size="14" v-show="record.url !== '-'">
                        <a v-if="showCopyFileName" @click="copyFileName(record.fileName)">复制名称</a>
                        <a @click.stop="onViewDetailsClick(record)">详情</a>
                        <a-dropdown :trigger="['click']">
                          <a>
                            更多
                            <DownOutlined />
                          </a>
                          <template #overlay>
                            <a-menu>
                              <a-menu-item><a v-hasPermission="`MATERIAL_UPDATE`"
                                              @click="onTableUpdateClick(record)">编辑</a></a-menu-item>
                              <a-menu-item><a v-hasPermission="`MATERIAL_DOWNLOAD`" :href="record.downloadurl"
                                              @click="getDownloadUrl(record)">下载</a></a-menu-item>
                              <a-menu-item><a v-hasPermission="`MATERIAL_DOWNLOAD`" :href="record.downloadurl"
                                              @click="copyUrl(record)">复制链接</a></a-menu-item>
                              <a-menu-item><a v-hasPermission="`MATERIAL_DELETE`"
                                              @click="openDelectModal(record)">删除</a></a-menu-item>
                            </a-menu>
                          </template>
                        </a-dropdown>
                      </a-space>
                    </div>
                  </div>
                  <div class="flexAlignBetween pl10">
                    <span class="material_title flexAlignCenter flexJustifyStart">
                      <span class="align-l textHide-3rows wrap_break_word" style="max-width: 18em">
                        <a-tooltip v-if="record.costStatus && record.costStatus != 0">
                          <template #title>
                            {{ columnMapConsumption(consumptionStatusOptions, record.costStatus)?.label }}<br />
                            累计消耗：{{ record.consumptionNum }}
                          </template>
                          <a-tag
                            :color="columnMapConsumption(consumptionStatusOptions, record.costStatus)?.color"> {{ columnMapConsumption(consumptionStatusOptions, record.costStatus)?.label
                            }}</a-tag>
                        </a-tooltip>
                        <a-tag color="#2F70F4">{{ columnMap(materialChannelOptions, record.materialChannel) }}</a-tag>

                        <a-tooltip v-for=" val in ttStatusOptions" :key="val.value">
                            <a-tooltip v-for=" tag in  ttStatusOptionsEQ(val,record.material_properties)" :key="tag">
                               <a-tag v-if="tag && tag.color" :color="tag.color"
                                      :key="tag.label"> {{ tag.label }} </a-tag>
                            </a-tooltip>
                        </a-tooltip>

                        <a-tooltip
                          v-if="record.gdtRejectCnt + record.ttRejectCnt + record.ksRejectCnt + record.qcRejectCnt > 0">
                          <template #title>
                            <p>{{ `腾讯广告：${record.gdtRejectCnt || 0}次   巨量引擎：${record.ttRejectCnt || 0}次`
                              }}</p>
                            <p>{{ `磁力引擎：${record.ksRejectCnt || 0}次   巨量千川：${record.qcRejectCnt || 0}次` }}</p>
                          </template>
                          <a-tag color="#555">拒审</a-tag>
                        </a-tooltip>
                        <a-tooltip :title="record.fileName">
                          <span> {{ record.fileName }}</span>
                        </a-tooltip>
                      </span>
                      <EditTwoTone class="material_title_edit ml10" @click="editMaterialTitleOpen(record)" />
                    </span>

                    <div>
                      <p class="align-l pb5" style="color: #686868" v-if="record.mediaChannel == '-'">
                        媒体：{{ record.mediaChannel }}</p>
                      <p class="align-l pb5" style="color: #686868" v-else>
                        媒体：<span
                        v-for="(item, columnIndex) in columnMediaArr(record.mediaChannel?record.mediaChannel:record.channel)"
                        :key="columnIndex">
                          {{ columnMediaType(item) }}
                          <span v-if="columnIndex !== columnMediaArr(record.mediaChannel)?.length - 1"></span>
                        </span>
                      </p>
                      <p class="align-l pb5" style="color: #686868">
                        产品：{{ record.ofProjectName ? record.ofProjectName : '-' }}</p>
                      <p class="flexJustifyBetween pb5" style="color: #686868; width: 220px">
                        <a-tooltip :title="record.producerName">
                          <span class="disBlock textHide align-l"
                                style="width: 50%">制片：{{ record.producerName ? record.producerName : '-' }}</span>
                        </a-tooltip>
                        <a-tooltip :title="record.postName">
                          <span class="disBlock textHide align-l"
                                style="width: 50%">后期：{{ record.postName ? record.postName : '-' }}</span>
                        </a-tooltip>

                      </p>
                      <p class="flexJustifyBetween pb5" style="color: #686868; width: 220px">
                        <a-tooltip :title="record.shootingName">
                          <!--                          record.shootingNames ?  record.shootingNames.join(',') :(record.shootingName?record.shootingName:'-')-->
                          <span class="disBlock textHide align-l"
                                style="width: 100%">摄像：{{ record.shootingNames ? record.shootingNames.join(',') : ((!record.shootingName || record.shootingName == null || record.shootingName == 0) ? '-' : record.shootingName)
                            }}</span>
                        </a-tooltip>
                      </p>
                      <p class="flexJustifyBetween pb5" style="color: #686868; width: 220px">
                        <a-tooltip :title="record.screenwriterName">
                          <span class="disBlock textHide align-l"
                                style="width: 100%">编导：{{ record.screenwriterNames ? record.screenwriterNames.join(',') : record.screenwriterName ? record.screenwriterName : '-'
                            }}</span>
                        </a-tooltip>
                      </p>
                    </div>
                  </div>
                </div>
                <span v-else>-</span>
              </template>
              <template v-else-if="text && text.quota">
                <div class="flexAlignCenter">
                  <a-tooltip v-if="text.num != 0">
                    <template #title>
                      <div class="flexJustifyBetween"><span>产品参考：</span>{{ text.quota.productQuota || '0' }}</div>
                      <template v-for="channelQuotaKey in Object.keys(text.quota.productChannelQuota)"
                                :key="channelQuotaKey">
                        <div class="flexJustifyBetween">
                          <span>{{ columnMap(channelList, channelQuotaKey) }}：</span>
                          {{ text.quota.productChannelQuota[channelQuotaKey] }}
                        </div>
                      </template>
                    </template>
                    <FallOutlined v-if="text.quota.trend === 'down'"
                                  :style="{ fontSize: '14px', marginRight: '6px', color: text.quota.showICON === 'good' ? '#52c41a' : '#ff4343' }" />
                    <RiseOutlined v-if="text.quota.trend === 'up'"
                                  :style="{ fontSize: '14px', marginRight: '6px', color: text.quota.showICON === 'good' ? '#52c41a' : '#ff4343' }" />
                  </a-tooltip>
                  {{ getCtrText(text, column) }}
                </div>
              </template>
            </template>
          </TableWithStatistic>
        </div>
      </div>

      <!-- 素材预览弹框 -->
      <a-modal :centered="true" class="video-modal" v-model:visible="viewActorVideosVisible" :mask-closable="false"
               :footer="null" :destroy-on-close="true" @cancel="closeModal">
        <p class="video-title">{{ playerOptions.title }}</p>
        <a-carousel arrows :draggable="true" :autoplay="true" class="video-carousel">
          <video-player v-if="playerOptions.src" v-bind="playerOptions"></video-player>
        </a-carousel>
      </a-modal>

      <!-- 批量修改关联产品 -->
      <a-modal v-model:visible="updateMaterialProductVisible" width="428px" title="修改关联产品" centered
               destroy-on-close @ok="confirmUpdateMaterial" @cancel="cancelUpdateMaterial" class="updateMaterialStyle">
        <a-form-item label="关联产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-select v-model:value="materialModalLargeData.projectCode" placeholder="请选择产品" show-search
                    :filter-option="true" option-filter-prop="label">
            <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value"
                             :label="option.label">{{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-modal>
      <!-- 素材详情 -->
      <a-drawer width="1080" :mask="false" v-model:visible="viewMaterialDrawervisible" class="viewMaterialDetails"
                :destroy-on-close="true" @close="closeModal">
        <template #title>
          {{ viewMaterialTitle }}
          <!-- <a-tag class="ml15" color="#E28705">待投放</a-tag> -->
        </template>
        <material-info ref="materialInfo" type="VIDEO" @uploadMaterial="materialEvent" v-if="viewMaterialDrawervisible"
                       :media-channel="mediaChannel" :material-details-message="materialDetailsMessage"
                       :filter-condition="filterCondition"></material-info>
      </a-drawer>
      <!-- 编辑脚本弹窗 -->
      <a-modal v-if="false" width="650px" v-model:visible="editScriptVisible" destroy-on-close title="编辑脚本"
               :ok-button-props="{ loading: editBtnLoading }" @ok="editScriptClickBtn" @cancel="editScriptClickCancel">
        <a-form ref="editScriptModalData" :rules="editScriptModalRules" :model="editScriptModalData">
          <a-row>
            <a-col :span="12">
              <a-form-item label="脚本类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
                <a-select v-model:value="editScriptModalData.mouthPlay" placeholder="请选择脚本类型" show-search
                          allow-clear @change="selectMouthPlay">
                  <a-select-option v-for="option in mouthplayOptions" :key="option.value" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="makeName">
                <a-input v-model:value="editScriptModalData.makeName" placeholder="请输入脚本名称" allow-clear />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="产品" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="projectCode">
                <a-select v-model:value="editScriptModalData.projectCode" placeholder="请选择产品" show-search
                          allow-clear :filter-option="true" option-filter-prop="label">
                  <a-select-option v-for="option in projectListOptions" :key="option.value" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="所属组" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="deptId">
                <a-select v-model:value="editScriptModalData.deptId" placeholder="请选择所属组" show-search allow-clear
                          :filter-option="true" option-filter-prop="label" @change="onDeptChange">
                  <a-select-option v-for="option in deptOptions" :key="option.value" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
                <a-date-picker v-model:value="editScriptModalData.uploadTime" :disabled="true" placeholder="请选择时间"
                               style="width: 100%" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="编导" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="screenwriterId">
                <a-select v-model:value="editScriptModalData.screenwriterId" placeholder="请选择编导" show-search
                          allow-clear :filter-option="true" option-filter-prop="label">
                  <a-select-option v-for="option in scenaristOptions" :key="option.value" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="editScriptModalData.mouthPlay != 'YSJJ'">
            <a-col :span="12">
              <a-form-item label="参演艺人" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="actorIdArray">
                <a-select v-model:value="editScriptModalData.actorIdArray" placeholder="请选择参演艺人" show-search
                          allow-clear mode="multiple" :filter-option="true" option-filter-prop="label">
                  <a-select-option v-for="option in actorOptions" :key="option.value" :value="option.value"
                                   :label="option.text">{{ option.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="摄像" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" name="userId">
                <a-select v-model:value="editScriptModalData.userId" placeholder="请选择摄像" show-search allow-clear
                          :filter-option="true" option-filter-prop="label">
                  <a-select-option v-for="option in phtographerOptions" :key="option.value" :value="option.value"
                                   :label="option.label">{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
      <!-- 自定义列弹窗 -->
      <customize-column :show-check="false" :selected-indicator="selectedIndicator" :fixed-list="fixedList"
                        title-tips="（勾选媒体渠道的指标，请在对应的渠道下查看）" :column-name="editTempName"
                        :menu-list="menuList" :modal-visible="modalVisible" @getDraggableList="getDraggableList"
                        @get-temp-data="getTempData" />
    </a-config-provider>
    <!-- 删除提示弹窗 -->
    <delect-modal :delect-modal-visible="delectModalVisible" @onOkClick="onDelectModalOk"
                  @onCancelClick="onDelectModalCancel" />
  </div>

  <!-- 批量推送 -->
  <batch-push v-model:visible="batchpushShow" :material-list="batchpushMaterial" material-type="VIDEO" />

  <!-- 上传、编辑视频 -->
  <upload-modal1 @edit-script="onEditScriptClick" @editMaterialTitle="editMaterialTitle" v-model:visible="uploadVisible" :mode="uploadMode" v-if="showUploadModal && !showV2" :modal-data="materialModalData" @after-ok="getMaterialPageListData" ref="uploadModal" type="VIDEO" :project-list-options="projectListOptions" :upload-person-list="unloadPersonList"></upload-modal1>
  <a-drawer v-if="showV2" width="719" :mask="false" v-model:visible="showUploadModal" class="uploadVideoInfo"
            :destroy-on-close="true" @close="closeModal">
    <template #title>
      上传视频
    </template>
    <upload-modal2 @edit-script="onEditScriptClick"
                  @editMaterialTitle="editMaterialTitle"
                  v-model:visible="uploadVisible" :mode="uploadMode"
                  @close-drawer="showUploadModal = false"
                  @open-drawer="openDrawer"
                  :modal-data="materialModalData" @after-ok="getMaterialPageListData" ref="uploadModal" type="VIDEO"
                  :project-list-options="projectListOptions" :upload-person-list="unloadPersonList"></upload-modal2>
  </a-drawer>
  <!--  前测界面-->
  <a-drawer v-if="showV2" width="1661" :mask="false" v-model:visible="showUploadModal2" class="uploadVideoInfo2"
            :destroy-on-close="true" @close="closeModal">
    <template #title>
      上传视频
    </template>
    <upload-modal-pre-test @edit-script="onEditScriptClick" @editMaterialTitle="editMaterialTitle"
                           v-model:visible="uploadVisible" :mode="uploadMode"
                           @close-drawer="showUploadModal2 = false"
                           :modal-data="materialModalData" @after-ok="getMaterialPageListData" ref="uploadModal"
                           type="VIDEO"
                           @open-drawer="openDrawer"
                           :project-list-options="projectListOptions"
                           :upload-person-list="unloadPersonList"></upload-modal-pre-test>
  </a-drawer>

  <add-similar-material ref="addSimilarMaterial"></add-similar-material>

  <!-- 素材名称修改 -->
  <EditMaterialTitle v-model:visible="editMaterialTitleVisible" :material-name="materialName"
                     @editTitle="getNewMaterialName" />
</template>

<script>
import _ from 'lodash';
import { getActorTimePageData } from '@/api/actor/todayActor.js';
import { Empty, message } from 'ant-design-vue';
import { TreeSelect } from 'ant-design-vue';
import moment from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
  DoubleRightOutlined,
  DownOutlined,
  CloudDownloadOutlined,
  CalendarTwoTone,
  CloudUploadOutlined,
  PlayCircleOutlined,
  FallOutlined,
  RiseOutlined,
  EditTwoTone,
} from '@ant-design/icons-vue';
import {
  getMaterialPageData,
  getMakeListDataByName,
  deleteMaterialById,
  getUserListData,
  updateLargeProductData,
  exportExcel,
  queryMediaAccountId,
  getAllTemp,
  getChooseTemp,
  getTempName,
  getMaterialNameList,
  getMaterialTotal,
  updateByFileName,
  getMaterialLibraryLabel,
} from '@/api/material/material.js';
import { getActorListDataByDate, getScriptListData, saveScriptData } from '@/api/material/script.js';
import { getRefreshTime } from '@/api/report';
import { getProjectListData, getProjectListByCompanyName } from '@/api/base/project.js';
import { getListByDeptTypesData, getDeptListDataByType } from '@/api/base/department.js';
import { getUserListDataByJob } from '@/api/base/user.js';
import { saveNotRestriction, postUpdateTemp, postDeleteTemp } from '@/api/customize/index.js';
import { getUrl, getDownloadUrl } from '@/utils/oss.js';
import { getOpUrl, getOpDownloadUrl } from '@/utils/op_oss.js';
import downloadFile from '@/utils/download.js';
import { debounce, addChineseUnit, isNumber, unique } from '@/utils/utils.js';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import videoPlayer from '@/components/Video/videoPlayer';
import caliber from '@/components/Customize/caliber.vue';
import CustomizeColumn from '@/components/Customize/customizeColumnModal.vue';
import TempSelect from '@/components/Customize/templateSelect.vue';
import DelectModal from '@/components/DelectModal/index.vue';
import UploadModal1 from './components/UploadModal.vue';
import UploadModal2 from '@/views/material2/components/UploadModal.vue';
import UploadModalPreTest from '@/views/material2/components/UploadModalPreTest.vue';
import BatchPush from '@/views/material/components/batchPushModal.vue';
import AddSimilarMaterial from './components/AddSimilarMaterial.vue';
import EditMaterialTitle from './components/EditMaterialTitle.vue';
import SelectedConditions from '@/views/report/selectedConditions.vue';
import MaterialInfo from './components/MaterialInfo.vue';
import TableWithStatistic from '@/components/TableWithStatistic/index.vue';
import { channelList, datePicker_dateRanges, materialType } from '@/config/globalData.js';
import { getDeptAllTreeList } from '@/api/org/account.js';
import { getIndustryList } from '@/api/customer/product.js';

export default {
  components: {
    DoubleRightOutlined,
    TableWithStatistic,
    SelectedConditions,
    BatchPush,
    caliber,
    TempSelect,
    videoPlayer,
    DownOutlined,
    CloudDownloadOutlined,
    CustomizeColumn,

    CalendarTwoTone,
    CloudUploadOutlined,
    DelectModal,
    PlayCircleOutlined,
    SelectMultiple,
    UploadModal1,
    UploadModal2,
    UploadModalPreTest,
    MaterialInfo,
    AddSimilarMaterial,
    FallOutlined,
    RiseOutlined,
    EditMaterialTitle,
    EditTwoTone,
  },
  data() {
    return {
      isMatch: 'Y',
      sitcomTypeList: [
        { label: '口播', value: 'MP' },
        { label: '剧情', value: 'JQ' },
        { label: '街采', value: 'JC' },
        { label: '多人分采', value: 'DRFC' },
        { label: '发布会', value: 'FBH' },
        { label: '访谈', value: 'FT' },
        { label: '原生vlog', value: 'YS_VLOG' },
        { label: '走播', value: 'ZB' },

        // { label: '耗时口播(废弃)', value: 'NOMP' },
        // { label: '情景剧(废弃)', value: 'QJJNOMP' },
        // { label: '剪辑(废弃)', value: 'YSJJ' },
        // { label: '采访(废弃)', value: 'CAIFANG' },
      ],
      mediaChannel: null,
      activeTempName: '默认指标',
      cascaderOption: [], //标签筛选项
      foldFilter: true, //是否折叠筛选区
      filterType: 'fileName', //筛选框选择素材名称或者素材id
      editNameId: null, // 编辑素材名称的ID
      editMaterialTitleVisible: false,
      materialName: {},
      SHOW_ALL: TreeSelect.SHOW_ALL,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      IndustryOptions: [], // 所属行业树形数据
      departmentOptions: [], // 部门树形数据
      replaceFields: { children: 'list', title: 'name', key: 'name', value: 'deptId' }, // 替换 treeNode 中 title,value,key,children 字段
      replaceIndustryFields: { title: 'value', key: 'id', value: 'id' }, // 替换 treeNode 中 title,value,key,children 字段
      replaceDepIdFields: { title: 'value', key: 'id', value: 'id' },
      treeDefaultExpandedKeys: [], //默认展开的节点开的节点
      nameDropdown: {
        lazyLoad: true,
        loadOver: false,
        total: 0,
      },
      actorList: [],
      scenaristList: [], // 筛选编导列表
      phtographerList: [], // 筛选摄像列表
      filemakerList: [], // 筛选制片列表
      tableTotal: {}, // 表格总计
      tablePostData: {}, // 表格筛选参数
      selectLoading: false,
      searchNameTime: null, // 计时器
      materialNameOptions: [], // 素材名称列表
      channelList: channelList, // 媒体渠道列表
      batchpushShow: false, // 批量推送弹窗
      batchpushMaterial: [], // 批量推送的素材
      uploadMode: '', //打开上传框的模式（add、edit、update、similar）
      uploadVisible: false, //上传框是否显示
      showUploadModal: false, //是否渲染上传框
      showV2: true,
      showUploadModal2: false,
      // 脚本列表加载标识
      gettingMakeListData: false,
      // 要删除的行数据
      delectRec: {},
      // 打开删除弹窗
      delectModalVisible: false,
      // 打开自定义列表弹窗
      modalVisible: false,
      selectMediaChannel: 'TT',
      // 所有配置项
      menuList: [],
      // 所有配置项
      menuListSource: [],
      // 选中的自定义列模板
      activeTemp: null,
      // 选中的自定义列模板
      editTempName: null,
      // 固定指标
      fixedList: [],
      // 已选指标项
      selectedIndicator: [],
      // 模板列表
      tempList: [],
      // 保存的值
      tempUserCustoms: [],
      // 选中的统计口径
      activeConfigType: 'REQUEST_TIME',
      // 素材源数据
      tableSourceData: [],
      // 是否显示具体数字
      amountShowDetail: false,
      locale,
      showCopyFileName: window.isSecureContext,//window.isSecureContext,
      materialIdOptions: [],
      // 上次刷新时间
      refreshTime: moment().format('YYYY-MM-DD hh:mm:ss'),
      editBtnLoading: false, // 确认按钮loading
      userInfoGrades: this.$store.state.oauth.userInfo.grades, //职级 (经理、组长、组员)
      userInfoJobs: this.$store.state.oauth.userInfo.jobs,
      dateRangeFormat: 'YYYY-MM-DD',
      viewActorVideosVisible: false, // 视频预览会话框
      playerOptions: { autoplay: true, width: 1000, height: 600 }, // 视频预览配置
      filterRules: { playTimeValue: [{ required: true, message: '请选择投放时间', trigger: 'blur', type: 'array' }] },
      dateRanges: datePicker_dateRanges,
      viewMaterialDrawervisible: false, //查看素材信息抽屉显示
      viewMaterialTitle: '', //详情抽屉标题
      materialDetailsKey: '1',
      unloadPersonList: [], //上传人员
      materialDetailsMessage: {}, //材料详情对象
      previewRecordList: [], // 预览的记录
      detailPersonInfo: {}, // 详情人员信息
      projectOptions: [], // 产品列表
      getScriptByTiProduct: [],
      materialHandleModalVisible: false, //新增编辑会话框显示
      editScriptVisible: false, //编辑关联脚本会话框
      materialModalTitle: '',
      excelExporting: false,
      order: 'desc', //正序排列
      materialModalData: { materialType: 'VIDEO' }, //新增编辑素材会话框中的数据

      filterCondition: {}, //筛选条件值
      materialTableData: [],
      projectListOptions: [],
      getAllDirectorList: [], //所有编导的信息
      showDragZone: false, // 拖放区域显示
      clearBlob: false, // 清除上传组件的Blob对象
      materialModalLargeData: {}, //批量修改
      getYsjjScriptByTProduct: {}, // 通过剪辑的时间和产品获取脚本
      filterDepIdList: [], //筛选的部门数据
      filterDepIdListAll: [], //筛选的部门数据
      material_properties: [],//筛选的标签
      mouthplayOptions: [
        { label: '口播', value: 'MP' },
        { label: '耗时口播', value: 'NOMP' },
        { label: '情景剧', value: 'QJJNOMP' },
        { label: '剪辑', value: 'YSJJ' },
        { label: '采访', value: 'CAIFANG' },
      ],
      materialChannelOptions: [
        // 素材类型选项
        { label: '情景', value: 'QJJ' },
        { label: '情景-口播', value: 'QJJ_KB' },
        { label: '情景-置换', value: 'QJJ_ZH' },
        { label: '游戏情景AE', value: 'GM_QJJ_AE' },
        { label: '游戏前贴AE', value: 'GM_QT_AE' },
        { label: '共建素材', value: 'GJSC' },
        { label: '共建-套皮10分钟', value: 'GJ_TP_10' },
        { label: '剪辑', value: 'YSJJ' },
        { label: '剪辑*2', value: 'YSJJ_2X' },
        { label: '剪辑*3', value: 'YSJJ_3X' },
        { label: '剪辑*4', value: 'YSJJ_4X' },
        { label: '剪辑*7', value: 'YSJJ_7X' },
        { label: '剪辑-混剪', value: 'HJ' },
        { label: '剪辑-Ai', value: 'AIJJ' },
        { label: 'AI剪辑(客供)', value: 'AIJJ_KG' },
        { label: '二创剪辑', value: 'EC' },
        { label: '情景-1/2', value: 'TP_QJJ' },
        { label: '套皮-后期-配音', value: 'TP_HQ_PY' },
        { label: '情景-混剪', value: 'TP_HQ_JZ' },
        { label: '套皮-后期-前贴', value: 'TP_HQ_QT' },
        /*   { label: '套皮-后期-5分钟', value: 'TP_HQ_5' },*/
        { label: '剪辑-1/5', value: 'TP_HQ_15' },
        { label: '剪辑-1/2', value: 'TP_HQ_30' },
        { label: '情景-二改', value: 'TP_EC' },
        { label: '情景-二改-外采', value: 'TP_EC_WC' },
        { label: '情景-混剪-外采', value: 'TP_HJ_WC' },
        { label: '情景-二改-外部', value: 'TP_EC_WB' },
        { label: '情景-混剪-外部', value: 'TP_HJ_WB' },

        { label: '采购', value: 'CG' },
        { label: '客户提供', value: 'KHTG' },
      ],
      materialType: {
        QJJ: '情景剧',
        YSJJ: '剪辑',
        CG: '采购',
        KHTG: '客户提供',
      },
      tableColumns: [
        {
          title: '素材ID',
          dataIndex: 'id',
          key: 'id',
          width: 60,
          fixed: 'left',
        },
        {
          title: '文件预览',
          dataIndex: 'url',
          key: 'url',
          align: 'center',
          sorter: true,
          defaultSortOrder: 'descend',
          sortDirections: ['descend', 'ascend'],
          slots: {
            customRender: 'url',
          },
          width: 430,
          fixed: 'left',
        },
      ],
      tableSourceColumns: [
        {
          title: '素材ID',
          dataIndex: 'id',
          key: 'id',
          width: 60,
          fixed: 'left',
        },
        {
          title: '文件预览',
          dataIndex: 'url',
          key: 'url',
          align: 'center',
          sorter: true,
          defaultSortOrder: 'descend',
          sortDirections: ['descend', 'ascend'],
          slots: {
            customRender: 'url',
          },
          width: 430,
          fixed: 'left',
        },
      ],
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      tableSorter: { order: 'desc', orderField: ['createTime'] },
      tableLoading: false,
      tableSetLoading: false,
      showUpload: false,
      allUploaded: true,
      selectedRowKeys: [], // 选中的行
      updateMaterialProductVisible: false, //关联产品会话框
      downloading: false,
      shootingName: '', //摄影
      makeId: Number,
      selectedRows: [],
      mediaList: [
        { text: '巨量引擎', value: 'TT' },
        { text: '腾讯广告', value: 'GDT' },
        { text: '腾讯广告3.0', value: 'GDT_V3' },
        { text: '磁力引擎', value: 'KS' },
        { text: '巨量千川', value: 'QC' },
        { text: '巨量引擎', value: 'TT_2' },
        { text: '巨量星图', value: 'TTSTAR' },
        { text: 'Bilibili', value: 'Bilibili' },
        { text: '爱奇艺', value: 'AQY' },
      ],
      editScriptModalData: {}, //关联脚本数据
      editScriptModalRules: {
        //关联脚本格式验证
        makeName: [{ required: true, message: '请选择名称', trigger: 'blur' }],
        projectCode: [{ required: true, message: '请选择产品', trigger: 'blur' }],
        deptId: [{ required: true, message: '请选择所属组', trigger: 'blur', type: 'number' }],
        screenwriterId: [{ required: true, message: '请选择编导', trigger: 'blur' }],
        actorIdArray: [{ required: true, type: 'array', message: '请选择参演艺人', trigger: 'blur' }],
        shootingIdArray: [{ required: true, type: 'array', message: '请选择摄像', trigger: 'blur' }],
        screenwriterIdArray: [{ required: true, type: 'array', message: '请选择编导', trigger: 'blur' }],
        userId: [{ required: true, message: '请选择摄像', trigger: 'blur' }],
      },
      deptOptions: [], //获取编辑关联脚本所属组的数据
      scenaristOptions: [], // 编导列表
      phtographerOptions: [], //摄像列表
      actorOptions: [], //参演艺人列表
      videoStyle: { width: '100%' },
      consumptionStatusOptions: [
        {
          label: '未使用',
          value: '未使用',
          color: '#818897',
        },
        {
          label: '有效',
          value: '有效',
          color: '#98704D',
        },
        {
          label: '优秀',
          value: '优秀',
          color: '#12A188',
        },
        {
          label: '爆款',
          value: '爆款',
          color: '#CF1790',
        },
        {
          label: '百万',
          value: '百万',
          color: '#CF1717',
        },
        {
          label: '五百万',
          value: '五百万',
          color: 'rgb(127 123 23 / 85%)',
        },
        {
          label: '一千万',
          value: '一千万',
          color: 'rgb(80 61 13 / 85%)',
        },
        {
          label: '低消耗',
          value: '低消耗',
          color: '#CF1717',
        },
        {
          label: '零消耗',
          value: '零消耗',
          color: '#CF1717',
        },
      ],
      propertiesDiagnosisSelectOptions :[
        {
          value: 'AD_HIGH_QUALITY_MATERIAL',
          label: 'AD优质素材',
          color: '#4CAF50' // 绿色表示高质量
        },
        {
          value: 'ECP_HIGH_QUALITY_MATERIAL',
          label: '千川优质素材',
          color: '#2196F3' // 蓝色表示优质
        },
        {
          value: 'FIRST_PUBLISH_MATERIAL',
          label: '首发素材',
          color: '#FF9800' // 橙色表示首发
        },
        {
          value: 'INEFFICIENT_MATERIAL',
          label: '低效素材',
          color: '#f44336' // 红色表示低效
        },
        {
          value: 'NON_AD_HIGH_QUALITY_MATERIAL',
          label: 'AD非优质素材',
          color: '#a5d6a7' //浅绿色表示非优质
        },
        {
          value: 'NON_ECP_HIGH_QUALITY_MATERIAL',
          label: '千川非优质素材',
          color: '#e0e0e0' // 浅灰色表示非优质
        },
        {
          value: 'NON_FIRST_PUBLISH_MATERIAL',
          label: '非首发素材',
          color: '#9c27b0' // 紫色表示非首发
        },
        {
          value: 'NON_INEFFICIENT_MATERIAL',
          label: '非低效素材',
          color: '#90caf9' // 蓝色较浅表示非低效
        }
      ],
      propertiesDiagnosisSelectOptionNames :[
        {
          value: 'AD优质素材',
          label: 'AD优质素材',
          color: '#4CAF50' // 绿色表示高质量
        },
        {
          value: '千川优质素材',
          label: '千川优质素材',
          color: '#2196F3' // 蓝色表示优质
        },
        {
          value: '首发素材',
          label: '首发素材',
          color: '#FF9800' // 橙色表示首发
        },
        {
          value: '低效素材',
          label: '低效素材',
          color: '#f44336' // 红色表示低效
        },
        {
          value: 'AD非优质素材',
          label: 'AD非优质素材',
          color: '#a5d6a7' //浅绿色表示非优质
        },
        {
          value: '千川非优质素材',
          label: '千川非优质素材',
          color: '#e0e0e0' // 浅灰色表示非优质
        },
        {
          value: '非首发素材',
          label: '非首发素材',
          color: '#9c27b0' // 紫色表示非首发
        },
        {
          value: '非低效素材',
          label: '非低效素材',
          color: '#90caf9' // 蓝色较浅表示非低效
        }
      ],
      ttStatusOptions: [
        {
          label: '低效素材',
          value: '低效素材',
          color: '#818897',
        },
        {
          label: '同质化挤压严重素材',
          value: '同质化挤压严重素材',
          color: '#98704D',
        },
        {
          label: '同质化素材风险-排队投放素材',
          value: '同质化素材风险-排队投放素材',
          color: '#75743d',
        },
        {
          label: 'AD 优质素材',
          value: 'AD 优质素材',
          color: '#ebc630',
        },
        {
          label: '千川优质素材',
          value: '千川优质素材',
          color: '#1736CFFF',
        },
        {
          label: '首发素材',
          value: '首发素材',
          color: '#22ea1a',
        },
        {
          label: '其它',
          value: '其它',
          color: '#8f8f8f',
        },

      ],
      rejectTagOptions: [
        { value: 'tt_reject_cnt', label: '巨量拒审' },
        { value: 'gdt_reject_cnt', label: '腾讯拒审' },
        { value: 'ks_reject_cnt', label: '磁力拒审' },
        { value: 'qc_reject_cnt', label: '千川拒审' },
        // { value: 'tt_2_reject_cnt', label: '巨量2.0拒审' },

      ],
    };
  },
  provide() {
    return {
      getProjectList: () => this.projectOptions,
      getMaterialDetail: () => this.materialDetailsMessage,
      previewRecordList: () => this.previewRecordList,
      getChannel: () => this.filterCondition.mediaChannelList,
      getMenuListSource: () => this.menuListSource,
      getFilterDepIdList: () => this.filterDepIdList,
      getMediaChannel: () => this.mediaChannel,
    };
  },
  computed: {
    isSelectMaterialSameProd() {
      if (this.selectedRows.length > 0) {
        let prodCode = this.selectedRows[0].ofProjectCode;
        return this.selectedRows.every(row => row.ofProjectCode == prodCode);
      }
      return false;
    },
    // 表格数据
    dataSource() {
      return this.materialTableData.slice(1) || [];
    },
    // 统计数据
    statistic() {
      return this.materialTableData[0] || {};
    },
    // 表格滚动宽度
    scrollWidth() {
      let width = 0;
      this.tableColumns.forEach(column => {
        const padding = 8 * 2;
        const textWidth = column.width || column.title.length * 12;
        const sortWdith = column.sorter ? 20 : 0;
        width = width + padding + textWidth + sortWdith;
      });
      return width;
    },
  },
  watch: {
    amountShowDetail(newVal) {
      if (!newVal) {
        this.formatAmount(this.materialTableData);
      } else {
        this.formatAmount(this.materialTableData, 'restore');
      }
    },
    filterCondition: {
      handler(newVal) {
        let localStorageValue = { ...newVal };
        localStorageValue.materialIdList = [];
        localStorage.videoFilterCondition = JSON.stringify(localStorageValue);
      },
      deep: true,
    },
    '$store.state.globalEvent.key'() {
      if (this.$store.state.globalEvent.event === 'uploadModalClose') {
        this.viewMaterialDrawervisible = false;
      }
    },
    viewMaterialDrawervisible(val) {
      if (val) {
        this.$nextTick(() => {
          let video = document.getElementById('material_detail_player');
          if (video) {
            video.addEventListener('play', e => {
              this.previewRecordList.forEach(item => {
                item.play = false;
              });
            });
          }
        });
      }
    },
  },
  created() {
    this.getAllTemp().then(() => {
      this.getTempName();
      this.getChanelChooseTemp(this.mediaChannel);
      if (this.$route.query.md5) {
        this.filterCondition = {
          playTimeValue: [moment().subtract(30, 'days'), moment()],
          startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
          endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
          fileName: '',
        };
        localStorage.removeItem('videoFilterCondition');
        this.filterCondition.md5 = this.$route.query.md5;
        this.filterCondition.mediaChannelList = this.$route.query.channel;
        this.filterCondition.playTimeValue = [this.$route.query.sd ? moment(this.$route.query.sd) : moment(), this.$route.query.ed ? moment(this.$route.query.ed) : moment()];
        this.filterCondition.startMaterialLaunchDate = this.$route.query.sd;
        this.filterCondition.endMaterialLaunchDate = this.$route.query.ed;
      } else {
        this.filterCondition.md5 = '';
        delete this.filterCondition.md5;
      }
      this.getMaterialPageListData(null, true);
    });
  },
  mounted() {
    this.getMakeListByName();
    this.getProjectList();
    this.getUserList();
    this.getScenaristListData();
    this.getUserListData();
    this.getFilemakerListData();
    this.getProjectListFilter();
    this.getAllActor();
    this.getDeptListByType();
    this.getMaterialNameList();
    this.getDepartmentTree();
    this.getIndustryList();
    this.getMaterialLibraryLabel();
    window.onresize = () => {
      return (() => {
        this.getVideoStyle();
      })();
    };
    document.addEventListener('click', this.closeModal);
    if (localStorage.videoFilterCondition && localStorage.videoFilterCondition !== 'undefined') {
      this.filterCondition = JSON.parse(localStorage.videoFilterCondition);

      // this.filterCondition.materialChannel = [];
      if (!this.filterCondition.playTimeValue) {
        this.filterCondition.playTimeValue = [moment().subtract(30, 'days'), moment()];
        this.filterCondition.startMaterialLaunchDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        this.filterCondition.endMaterialLaunchDate = moment().format('YYYY-MM-DD');
      } else {
        this.filterCondition.playTimeValue = [moment(this.filterCondition.playTimeValue[0]), moment(this.filterCondition.playTimeValue[1])];
      }
    } else {
      this.filterCondition = {
        playTimeValue: [moment().subtract(30, 'days'), moment()],
        startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
      };
    }
    this.$nextTick(() => {
      this.setTableSticky();
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeModal);
    const drawer = document.querySelector('.viewMaterialDetails');
    if (drawer)
      drawer.removeEventListener('click', e => {
        e.stopPropagation();
      });
  },
  methods: {
    openDrawer(param) {
      // drawer1为普通上传界面,drawer2为前测上传界面
      if (param === 'drawer1') {
        this.showV2 = false;
        this.showUploadModal = true;
      } else if (param === 'drawer2') {
        this.showV2 = true;
        this.showUploadModal2 = true;
      }
    },
    getCtrText(text, column) {
      if (!text || text.num === '-') {
        return '-';
      } else if (column.dataIndex === 'ctr') {
        return text.num + '%';
      }
      return text.num;
    },
    /**
     * @description: 获取素材标签列表
     * @param {*}
     * @return {*}
     */
    async getMaterialLibraryLabel() {
      let res = await getMaterialLibraryLabel();
      if (res.code == 0) {
        this.cascaderOption = res.data;
      }
    },
    /**
     * @description: 限制消耗范围
     * @param {*}
     * @return {*}
     */
    onCostChange() {
      let minCostTotal = parseInt(this.filterCondition.minCostTotal);
      let maxCostTotal = parseInt(this.filterCondition.maxCostTotal);
      minCostTotal && (this.filterCondition.minCostTotal = minCostTotal > 0 ? minCostTotal : 0);
      maxCostTotal && (this.filterCondition.maxCostTotal = maxCostTotal > 0 ? maxCostTotal : 0);
      if (minCostTotal && (maxCostTotal || maxCostTotal == 0)) {
        minCostTotal > maxCostTotal && this.$message.error('请输入正确的消耗值范围');
      }
    },
    // 设置分页显隐
    setPagination(antTableBody, pagination) {
      if (!antTableBody) {
        antTableBody = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-body')?.[0];
      }
      if (!pagination) {
        pagination = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-pagination')?.[0];
      }
      let show = 'none';
      // 即将触底
      if (antTableBody.scrollHeight - antTableBody.scrollTop <= antTableBody.clientHeight + 44) {
        show = 'block';
      } else {
        show = 'none';
      }
      pagination && (pagination.style.display = show);
    },
    // 设置表格粘滞滚动
    setTableSticky() {
      const antTableBody = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-body')?.[0];
      let pagination = this.$refs.videoTable?.$el?.getElementsByClassName('ant-table-pagination')?.[0];
      let timer;
      if (antTableBody) {
        antTableBody.addEventListener('scroll', e => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            this.setPagination(antTableBody, pagination);
          }, 10);
          const filterHeight = parseFloat(getComputedStyle(this.$refs.filter).height) + 12; //12是filter的下外边距
          this.$refs.filter.style.marginTop = `-${e.target.scrollTop < filterHeight ? e.target.scrollTop : filterHeight}px`;
        });
      }
    },
    /**
     * @description: 素材名称enter事件
     * @param {*} e
     * @return {*}
     */
    autoComplateEnter(e) {
      e.stopPropagation();
      this.onFilterBtnClickAndFlush();
    },
    /**
     * @description: 隐藏 tag 时显示的内容
     * @param {*} omittedValues
     * @return {*}
     */
    maxTagPlaceholder(omittedValues) {
      let values = '';
      omittedValues?.forEach(item => {
        values += item.label + ';';
      });
      return (
        <aTooltip title={values}>
          <span>+</span>
          <span style={{ margin: '0 5px' }}>{omittedValues.length}</span>
          <span>...</span>
        </aTooltip>
      );
    },
    /** 打开编辑素材名称弹窗
     * @param {*}
     * @return {*}
     */
    editMaterialTitleOpen(record) {
      this.materialName.oldName = record.fileName;
      this.editNameId = record.id;
      this.editMaterialTitleVisible = true;
    },
    //获取标签数组列表
    getTagList() {
      let array = [];
      for (let key in this.ttStatusOptions) {
        let obj = this.ttStatusOptions[key];
        array.push(obj.label);
      }
      return array;
    },
    /** 获取新的素材名称
     * @param {*} value
     * @return {*}
     */
    async getNewMaterialName(value) {
      if (value) {
        // let materialType = this.materialName.oldName?.split('.')?.[1] || '';
        let materialType = this.materialName.oldName?.substring(this.materialName.oldName.lastIndexOf('.') + 1) || '';
        if (materialType.length > 4) {
          materialType = 'mp4';
        }
        let fileName = value.indexOf(materialType) !== -1 ? value : value + '.' + materialType;
        let res = await updateByFileName({ id: this.editNameId, fileName: fileName });
        if (res.code == 0) {
          this.$message.success('素材名称修改成功');
          this.editMaterialTitleVisible = false;
          this.materialName.newName = null;
          this.getMaterialPageListData();
        } else {
          this.$message.error('素材名称修改失败');
        }
      }
    },
    // 获取所属行业树形列表
    async getIndustryList() {
      let res = await getIndustryList();
      this.IndustryOptions = res.data;
    },
    /** 编辑名称
     * @param {*}
     * @return {*}
     */
    editMaterialTitle(title) {
      this.viewMaterialTitle = title;
      this.getMaterialPageListData();
    },
    // 获取部门树形数据
    async getDepartmentTree() {
      let res = await getDeptAllTreeList();
      if (res.code === 0 && res.sysDeptEntity) {
        this.departmentOptions = [res.sysDeptEntity];
        this.treeDefaultExpandedKeys = [res.sysDeptEntity.name];
      }
    },
    // 获取筛选编导列表
    async getScenaristListData() {
      let res = await getUserListData({ jobs: 'SCENARIST' });
      this.scenaristList =
        res.page &&
        res.page.list.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    // 获取筛选摄像列表
    async getUserListData() {
      let res = await getUserListData({ jobs: 'PHOTOGRAPHER' });
      this.phtographerList =
        res.page &&
        res.page.list?.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    // 获取筛选制片列表
    async getFilemakerListData() {
      let res = await getUserListData({ jobs: 'FILEMAKER' });
      this.filemakerList =
        res.page &&
        res.page.list?.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    /** 是否禁用
     * @param {*} value
     * @return {*}
     */
    isDisabled(value) {
      return (Array.isArray(value) ? value.length > 0 : value) ? (this.tableLoading || value ? true : false) : this.tableLoading;
    },
    /** 下拉框失去焦点
     * @param {*}
     * @return {*}
     */
    onSelectBlur() {
      if (!this.filterCondition.materialIdList || this.filterCondition.materialIdList.length == 0) {
        this.getMaterialNameList();
      }
    },
    /** 模糊查询素材名称
     * @param {String} value 关键字
     * @return {*}
     */
    getMaterialNameList(filename, pageData) {
      this.selectLoading = true;
      if (this.searchNameTime) {
        clearTimeout(this.searchNameTime);
      }
      this.searchNameTime = setTimeout(() => {
        const param = {
          size: pageData?.size || 50,
          page: pageData?.page || 1,
          fileName: filename || '',
          materialId: '',
          materialType: 'VIDEO',
        };
        getMaterialNameList(param).then(res => {
          const arr = res?.data.records || [];
          this.nameDropdown.total = res.data.total;
          if (!pageData) {
            this.materialNameOptions = [];
          }
          this.materialNameOptions = this.materialNameOptions.concat(arr).map(item => ({
            key: item.id,
            value: item.fileName,
            label: item.fileName,
          }));
          this.selectLoading = false;
        });
      }, 600);
    },
    toBottom() {
      const len = this.materialNameOptions.length;
      let page = Math.ceil(len / 50);
      if (len == this.nameDropdown.total) {
        this.nameDropdown.loadOver = true;
      } else {
        this.getMaterialNameList('', { page: page + 1, size: 50 });
      }
    },
    // 素材详情抛出的事件
    materialEvent(data) {
      this.uploadMode = data.type;
      this.showUploadModal = true;
      this.$nextTick(() => {
        this.uploadVisible = true;
      });
    },
    /** 表格行点击
     * @param {*} record 行数据
     * @param {*} index 行索引
     * @param {*} event 点击元素
     * @return {*}
     */
    rowClick(record, index) {
      return {
        onClick: event => {
          if (event.path?.[0].tagName.indexOf('TD') !== -1) {
            this.closeModal();
          }
        },
      };
    },
    /** 表格表头行点击
     * @param {*} column 列数据
     * @return {*}
     */
    rowHeaderClick(column) {
      return {
        onClick: event => {
          if (event.path?.[0].tagName.indexOf('TH') !== -1) {
            this.closeModal();
          }
        },
      };
    },

    /** 打开删除弹窗
     * @param {Object} record 要删除的行
     * @return {*}
     */
    openDelectModal(record) {
      this.delectRec = record;
      this.delectModalVisible = true;
    },
    /** 删除弹窗确认
     * @param {*}
     * @return {*}
     */
    onDelectModalOk() {
      this.confirmDeleteMaterial(this.delectRec);
      this.delectModalVisible = false;
    },
    /**删除弹窗取消
     * @param {*}
     * @return {*}
     */
    onDelectModalCancel() {
      this.delectModalVisible = false;
    },
    /** 格式化数组数据
     * @param {Array} arr 需要格式化的数据
     * @param {Boolean} type 操作类型
     * @return {*}
     */
    formatAmount(arr, type = 'format') {
      const _this = this;
      arr?.forEach((item, index) => {
        Object.keys(item)?.forEach(key => {
          if (type === 'format') {
            if (isNumber(item[key] - 0) && key.indexOf('Code') === -1 && key !== 'id') {
              item[key] = addChineseUnit(item[key] - 0);
              item['_' + key] = _this.tableSourceData[index]?.[key];
            }
          } else if (key !== 'url') {
            let value = (item[key].num || item[key]) + '';
            value = value?.replace('万', '').replace('亿', '') - 0;
            if (value && isNumber(value)) {
              item[key] = _this.tableSourceData[index]?.[key];
            }
          }
        });
      });
    },
    /** 口径下拉变化
     * @param {*}
     * @return {*}
     */
    onTJKJSelect(item) {
      this.activeConfigType = item;
      // this.activeTemp = '默认指标';
      this.getMaterialPageListData(null, true);
    },
    /** 获取素材库所有指标
     * @param {*}
     * @return {*}
     */
    async getAllTemp() {
      let res = await getAllTemp();
      res.list?.forEach(item => {
        item.info?.forEach(childItem => {
          childItem.customConfigId = childItem.id;
          childItem.info?.forEach(info => {
            info.customConfigId = info.id;
          });
        });
      });
      this.menuList = res.list;
      this.menuListSource = _.cloneDeep(res.list);
      this.mediaChannelChange(this.filterCondition.mediaChannelList);
    },
    /** 获取选中的模板已选中指标
     * @param {*}
     * @return {*}
     */
    async getChooseTemp(tempName) {
      if ((this.activeTemp && this.activeTemp !== '默认指标') || (tempName && tempName !== '默认指标')) {
        let res = await getChooseTemp(tempName || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);
      }
    },
    /*默认渠道指标*/
    async getChanelChooseTemp(chanel) {

      if ((chanel && (chanel === 'TT' || chanel === 'TT_2'))) {
        this.activeTemp = '巨量关键指标';
        this.tempName = '巨量关键指标';
        this.activeTempName = '巨量关键指标';

        let res = await getChooseTemp('巨量关键指标' || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);

        // this.tempList = res.list;
        // this.tempList.unshift({ tempName: '巨量关键指标' });

      } else if ((chanel && (chanel === 'GDT' || chanel === 'GDT_V3'))) {
        this.activeTemp = '腾讯关键指标';
        this.tempName = '腾讯关键指标';
        this.activeTempName = '腾讯关键指标';

        let res = await getChooseTemp('腾讯关键指标' || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);


        // this.tempList = res.list;
        // this.tempList.unshift({ tempName: '腾讯关键指标' });
      } else if ((chanel && chanel === 'QC')) {
        this.activeTemp = '千川关键指标';
        this.tempName = '千川关键指标';
        this.activeTempName = '千川关键指标';

        let res = await getChooseTemp('千川关键指标' || this.activeTemp);
        this.selectedIndicator = res.list?.[0]?.info;
        this.setColumn(res.list?.[0]?.info);

        // this.tempList = res.list;
        // this.tempList.unshift({ tempName: '腾讯关键指标' });
      } else {
        const list = this.menuListSource.filter(item => {
          return item.key === 'SHARE_DATA';
        })?.[0]?.info;
        this.setColumn(list);

        this.activeTemp = '默认指标';
        this.activeTempName = '默认指标';
        // this.tempList = list;
        // this.tempList.unshift({ tempName: '默认指标' });

      }
    },
    /** 设置自定义列
     * @param {*}
     * @return {*}
     */
    setColumn(arr) {
      this.tableSetLoading = true;
      const _this = this;
      setTimeout(() => {
        _this.tableColumns = _.cloneDeep(_this.tableSourceColumns);
        arr?.forEach(item => {
          const itemChannel = item.oneColumnKeys?.split('_')[0];

          if ((_this.filterCondition.mediaChannelList && _this.filterCondition.mediaChannelList.indexOf(itemChannel) !== -1) || itemChannel === 'SHARE') {
            _this.tableColumns?.push({
              title: item.columnValue.indexOf('率') !== -1 ? item.columnValue + '(%)' : item.columnValue,
              dataIndex: item.columnKey,
              sorter: true,
              sortDirections: ['descend', 'ascend'],
              slots: { customRender: item.columnKey },
            });
          }
        });
        _this.tableColumns = unique(_this.tableColumns);
        _this.tableSetLoading = false;
      }, 10);
    },
    /** 获取模板名称
     * @param {*}
     * @return {*}
     */
    getTempName() {
      getTempName().then(res => {
        if (res.code == 0) {
          this.activeTemp = '默认指标';
          this.tempList = res.list;
          this.tempList.unshift({ tempName: '默认指标' });
          if (this.activeTemp && this.activeTemp !== '默认指标') {
            this.getChooseTemp();
          } else {
            const list = this.menuListSource.filter(item => {
              return item.key == 'SHARE_DATA';
            })?.[0]?.info;
            this.setColumn(list);
          }
        }
      });
    },
    /** 关闭弹窗
     * @param {*}
     * @return {*}
     */
    getTempData(tempUserCustoms) {
      if (tempUserCustoms) {
        this.tempUserCustoms = tempUserCustoms;
        let postData = {};
        if (this.operatType === 'add') {
          postData = {
            tempUserCustoms: tempUserCustoms,
          };
          saveNotRestriction(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('新增成功');
              this.modalVisible = false;
              this.getTempName();
              this.selectedIndicator = [];
            } else {
              this.modalVisible = 'loading';
            }
          });
        } else if (this.operatType === 'edit') {
          postData = {
            oldTempName: this.activeTemp,
            tempUserCustoms: tempUserCustoms,
          };
          postUpdateTemp(postData).then(res => {
            if (res.code === 0) {
              this.$message.success('修改成功');
              this.modalVisible = false;
              this.selectedIndicator = [];
              this.getTempName();
            } else {
              this.modalVisible = 'loading';
            }
          });
        }
      } else {
        this.modalVisible = false;
        this.selectedIndicator = [];
      }
      // this.modalVisible = false;
    },
    /** 模板下拉变化
     * @param {*} tempName
     * @return {*}
     */
    getTempNameValue(tempName) {
      this.activeTemp = tempName;
      if (this.activeTemp && this.activeTemp !== '默认指标') {
        this.getChooseTemp();
      } else {
        const list = this.menuListSource.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.setColumn(list);
      }
    },
    /** 媒体渠道筛选
     * @param {*}
     * @return {*}
     */
    async mediaChannelChange(value) {
      // this.getMaterialPageListData();
      this.mediaChannel = value;
      this.menuList = this.menuListSource.filter(item => {
        return item.key == 'SHARE_DATA' || item.key.indexOf(value) != -1;
      });
      // if (this.activeTemp && this.activeTemp !== '默认指标') await this.getChooseTemp();
      // this.selectMediaChannel = value ;
      //关联对应默认渠道
      await this.getChanelChooseTemp(value);

      this.onFilterBtnClick();

    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },
    /** 点击下拉列表编辑
     * @param {String} tempName 模板名称
     * @return {*}
     */
    async editTemp(tempName) {
      this.operatType = 'edit';
      this.editTempName = tempName;
      if (this.editTempName && this.editTempName !== '默认指标') await this.getChooseTemp(tempName);
      this.modalVisible = true;
    },
    /** 点击下拉列表删除
     * @param {String} tempName 模板名称
     * @return {*}
     */
    deleteTemp(tempName) {
      this.operatType = 'delete';
      postDeleteTemp([tempName]).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功');
          this.selectedIndicator = [];
          this.getTempName();
          const list = this.menuListSource.filter(item => {
            return item.key == 'SHARE_DATA';
          })?.[0]?.info;
          this.setColumn(list);
        }
      });
    },
    /** 新增自定义指标
     * @param {*}
     * @return {*}
     */
    addTemp() {
      this.operatType = 'add';
      this.editTempName = null;
      if (this.menuListSource.length > 0) {
        this.selectedIndicator = this.menuListSource.filter(item => {
          return item.key == 'SHARE_DATA';
        })?.[0]?.info;
        this.modalVisible = true;
      } else {
        this.$message.error('当前口径无可设置指标，请联系后台管理员！');
      }
    },

    // 设置table总计多选框不可选
    getCheckboxProps(record) {
      if (record.id === '总计') {
        // 返回的是CheckBox的属性
        return { disabled: true };
      }
    },
    /** 获取上次刷新时间
     * @param {*}
     * @return {*}
     */
    getRefreshTime() {
      getRefreshTime('SC').then(res => {
        this.refreshTime = res.list;
      });
    },
    // 获取参演艺人
    async getActorListByDate() {
      let res = await getActorListDataByDate({ date: this.editScriptModalData.uploadTime });
      this.actorOptions =
        res.list &&
        res.list.map(actor => {
          return {
            cost: actor.cost,
            text: actor.actorName,
            value: actor.actorId.toString(),
            key: actor.actorId.toString(),
          };
        });
    },
    // 获取摄像列表
    async getUserListByJob() {
      let res = await getUserListDataByJob({
        job: 'PHOTOGRAPHER',
        deptId: this.editScriptModalData.deptId,
        findAll: true,
      });
      this.phtographerOptions =
        res.list &&
        res.list.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },
    // 获取编导列表
    async getScenaristListByJob() {
      let res = await getUserListDataByJob({
        job: 'SCENARIST',
        deptId: this.editScriptModalData.deptId,
        findAll: true,
      });
      this.scenaristOptions =
        res.list &&
        res.list.map(ph => {
          return {
            label: ph.userCode,
            value: ph.userId.toString(),
          };
        });
    },

    // 获取编辑脚本所属组
    async getDeptListEditScript() {
      let res = await getDeptListDataByType({ deptType: 'VIDEO' });
      this.deptOptions =
        res.list &&
        res.list.map(d => {
          return { label: d.name, value: d.deptId };
        });
    },
    // 改变编辑脚本的部门
    onDeptChange() {
      this.editScriptModalData.screenwriterId = null;
      this.editScriptModalData.userId = null;
      this.getScenaristListByJob();
      this.getUserListByJob();
    },
    // 打开关联脚本会话框
    async onEditScriptClick() {
      const makeObj = this.getScriptByTiProduct.find(item => {
        return item.makeCode == this.materialModalData.makeCode;
      });
      let makeId = makeObj && makeObj.id;
      let postData = {
        page: '1',
        limit: '10',
        makeIds: [makeId],
      };
      let res = await getScriptListData(postData);
      this.editScriptModalData = res.page && res.page.list[0];
      this.editScriptModalData.actorIdArray =
        this.editScriptModalData &&
        this.editScriptModalData.actorInfoList.map(item => {
          return item.actorId.toString();
        });
      this.editScriptModalData.screenwriterIdArray =
        this.editScriptModalData &&
        this.editScriptModalData.screenwriterBOList.map(item => {
          return item.screenwriter_id.toString();
        });

      this.editScriptModalData.shootingIdArray =
        this.editScriptModalData &&
        this.editScriptModalData.shootingBOList.map(item => {
          return item.shooting_id.toString();
        });

      this.editScriptVisible = true;
      this.getDeptListEditScript(); // 获取编辑脚本所属组
      this.getScenaristListByJob(); //获取编导
      this.getActorListByDate(); //获取艺人
      this.getUserListByJob(); //获取摄像
    },
    // 确认编辑关联脚本
    async editScriptClickBtn() {
      await this.$refs.editScriptModalData.validate();
      let objParams = {
        ...this.editScriptModalData,
      };
      this.editBtnLoading = true;
      objParams.shootingId = objParams.userId;
      objParams.id = this.editScriptModalData.makeId;
      objParams.uploadTime = this.editScriptModalData.uploadTime.split(' ')[0];
      let res = await saveScriptData(objParams);
      this.editBtnLoading = false;
      if (res.code === 0) {
        this.$message.success('编辑成功!');
        this.editScriptVisible = false;
      }
    },
    // 取消编辑关联脚本
    editScriptClickCancel() {
      this.editScriptModalData = {};
      this.editBtnLoading = false;
    },
    // 获取媒体渠道
    columnMediaType(key) {
      // console.log("获取媒体渠道===========",key)
      if (key) {
        return this.mediaList.filter(item => {
          return item.value == key;
        })[0].text;
      }
    },
    // 主页表格媒体
    columnMediaArr(key) {
      let mediaArr = [];
      if (key != '-' && key) {
        mediaArr = key.split(',');
        return mediaArr;
      }
    },

    // 获取筛选部门数据
    async getDeptListByType() {
      let res = await getListByDeptTypesData({ deptType: ['OPERATION'] });
      if (res.code === 0) {
        // this.filterDepIdList = res.page.records;
        this.filterDepIdList = res.data.filter(item => {
          return item.name === '业务二部' || item.name === '业务一部' || item.name === '业务三部' || item.name === '业务五部' || item.name === '业务六部' || item.name === '洽尔' || item.name === '客户自运营';
        });
      }
      // let resAll = await getListByDeptTypesData({ deptType: ['OPERATION', 'VIDEO', 'OTHER'] });
      let resAll = await getListByDeptTypesData({ deptType: ['OPERATION', 'VIDEO'] });
      if (resAll.code === 0) {
        // this.filterDepIdListAll = resAll.page.records;
        this.filterDepIdListAll = res.data.filter(item => {
          return item.name === '业务二部' || item.name === '业务一部' || item.name === '业务三部' || item.name === '业务五部' || item.name === '业务六部' || item.name === '洽尔' || item.name === '捕鱼团队';
        });
      }
    },

    queryMediaAccountId() {
      debounce(queryMediaAccountId(this.filterCondition.materialIds), 3000).then(data => {
        this.materialIdOptions = data.list;
      });
    },
    onInputKeyDown(e) {
      if (e.key == 'Enter') {
        this.getMaterialPageListData(null, true);
        this.selectedRows = [];
        this.selectedRowKeys = [];
      }
    },
    viewDetailFromPreview(record) {
      this.viewMaterialDrawervisible = true;
      this.viewMaterialTitle = record.fileName + record.id + record.postName;
      this.materialDetailsMessage = record;
      this.detailPersonInfo = {};
      this.materialDetailsKey = '1';
    },
    download(src, filename, callback) {
      this.downloading = true;
      let _this = this;
      downloadFile(src, filename, undefined, () => {
        callback && callback();
        _this.downloading = false;
      });
    },
    // 导出报表
    async onExportExcelBtnClick() {
      console.error('视频库导出报表=====================================');
      this.excelExporting = true;
      this.tablePostData.costStatus && (this.tablePostData.costStatus = [this.tablePostData.costStatus]);

      let postData = {
        ...this.tablePagination,
        ...this.filterCondition,
        limit: this.tablePagination.limit,
        userIdList: this.filterCondition.userIdList?.map(item => item.value) || [],
        useDeptIds: this.filterCondition.useDeptIds?.map(item => item.value) || [],
        // deptIdList: this.filterCondition.deptIdList?.map(item => item.value) || [],
        deptIdList: this.filterCondition.deptIdList ? this.filterCondition.deptIdList : [],
        shootingNames: this.filterCondition.shootingNames?.map(item => item.label) || [],
        screenwriterNames: this.filterCondition.screenwriterNames?.map(item => item.label) || [],
        producerNames: this.filterCondition.producerNames?.map(item => item.label) || [],

        materialType: 'VIDEO',
        ...this.tableSorter,
        timeline: this.filterCondition.mediaChannelList === 'GDT' || this.filterCondition.mediaChannelList === 'GDT_V3' ? this.activeConfigType : null,
      };
      if (postData.projectCodeList) {
        postData.projectCodeList = [];
        this.filterCondition.projectCodeList.forEach(p => {
          postData.projectCodeList.push(p.value);
        });
      }

      if (this.filterCondition.mediaChannelList) {
        postData.mediaChannelList = Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList];
      } else {
        postData.mediaChannelList = [];
      }
      postData.columns = [];
      this.tableColumns.forEach(item => {
        if (item.dataIndex !== 'id' && item.dataIndex !== 'url') {
          postData.columns.push({ columnKey: item.dataIndex, columnValue: item.title });
        }
      });
      if (postData.materialIds) {
        if (this.filterCondition.materialIds.indexOf(' ') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(' ');
        } else if (this.filterCondition.materialIds.indexOf(',') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(',');
        } else if (this.filterCondition.materialIds.indexOf(';') > 0) {
          postData.materialIdList = this.filterCondition.materialIds.split(';');
        } else {
          postData.materialIdList = [this.filterCondition.materialIds];
        }
      }

      let res = await exportExcel(postData);
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
      let objectUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = objectUrl;
      // link.download = `${this.channelMap[this.activeTabKey]}-${this.dimensionText}`;
      // if (this.filterConditions.startTime) link.download += `-${this.filterConditions.startTime}`;
      // if (this.filterConditions.endTime) link.download += `至${this.filterConditions.endTime}`;
      link.download = 'VIDEO素材报表.xlsx';
      link.click();
      URL.revokeObjectURL(objectUrl);
      this.excelExporting = false;
    },
    // 批量推送
    batchPush() {
      this.batchpushShow = true;
      this.batchpushMaterial = this.selectedRows.map(r => {
        return { materialId: r.id, url: r.url, md5: r.md5, fileName: r.fileName };
      });
    },
    // 修改批量素材产品
    batchUpdate() {
      this.closeModal();
      this.updateMaterialProductVisible = true;
    },
    //确认修改批量素材
    async confirmUpdateMaterial() {
      let ids = [];
      this.selectedRows.forEach(item => {
        if (item.id !== '总计') {
          ids.push(item.id);
        }
      });
      let postParams = {
        ids: ids,
        projectCode: this.materialModalLargeData.projectCode,
      };
      let res = await updateLargeProductData(postParams);
      if (res.code === 0) {
        this.$message.success('批量修改成功');
        this.getMaterialPageListData();
        this.updateMaterialProductVisible = false;
        this.materialModalLargeData = {};
      }
    },
    // 取消批量修改
    cancelUpdateMaterial() {
      this.materialModalLargeData = {};
    },
    // 筛选的产品列表
    async getProjectListFilter() {
      let res = await getProjectListByCompanyName({});
      if (res.code == 0) {
        this.projectOptions = res.page.list;
      }
    },
    async getAllActor() {
      const postParams = { limit: 1000, page: 1 };
      const res = await getActorTimePageData(postParams);
      const list = res?.page?.list || [];
      const set = new Set();
      const actorList = [];
      list.forEach(item => {
        if (item.actorInfoList) {
          item.actorInfoList.forEach(actor => {
            if (!set.has(actor.actorId)) {
              actorList.push({
                label: actor.actorName,
                value: actor.id,
                key: actor.id,
              });
              set.add(actor.actorId);
            }
          });
        }
      });
      this.actorList = actorList;
    },

    /** 获取上传人员
     * @param {*}
     * @return {*}
     */
    async getUserList() {
      // const deptIdList = this.filterCondition.deptIdList?.map(item => item.value);
      const deptIdList = this.filterCondition.deptIdList ? this.filterCondition.deptIdList : [];
      let res = await getUserListData({ deptIdList });
      if (res.code === 0) {
        //admin
        if (this.$store.state.oauth.userInfo.userId === 1) {
          this.unloadPersonList = res.page?.list;
          return;
        }
        this.unloadPersonList = res.page?.list;
        // this.unloadPersonList = res.page.list.filter(item => {
        //   return item.userCode !== 'admin';
        // });
      }
    },
    // 批量下载素材
    async batchDownload() {
      //循环下载文件
      let index = 0;
      for (const row of this.selectedRows) {
        if (row.url.startsWith('/')) {
          let downurl = await this.getDownloadUrl(row);
          setTimeout(() => {
            window.location.href = downurl;
          }, 1000 * index);
          index++;
        }
      }
    },

    // 确认删除素材
    async confirmDeleteMaterial(record) {
      let postParams = [];
      postParams.push(record.id);
      let res = await deleteMaterialById(postParams);
      if (res.code === 0) {
        this.$message.success('删除成功');
        this.getMaterialPageListData();
        this.selectedRows = this.selectedRows.filter(sr => sr.md5 != record.md5);
        this.selectedRowKeys = this.selectedRowKeys.filter(srk => srk.id + srk.mediaChannel != record.id + record.mediaChannel);
      }
    },
    /** 选中框发生变化
     * @param {*} selectedRowKeys 已选中的行
     * @return {*}
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    async getOSSUrl(record) {
      if (this.isMatch === 'N') {
        return record.url;
      }
      if (!record.ossurl) {
        if (record.makeCode?.startsWith('op')) {
          record.ossurl = await getOpUrl(record.url);
        } else {
          record.ossurl = await getUrl(record.url);
        }
      }
      return record.ossurl;
    },
    getThumbOSSUrl(record) {
      if (this.isMatch === 'N') {
        return record.thumb;
      }
      if (!record.thumbossurl) {
        if (record.makeCode?.startsWith('op')) {
          getOpUrl(record.thumb).then(url => {
            record.thumbossurl = url;
          });
        } else {
          getUrl(record.thumb).then(url => {
            record.thumbossurl = url;
          });
        }
      } else {
        return record.thumbossurl;
      }
    },
    /** 获取文件下载链接
     * @param {*} record
     * @return {*}
     */
    async getDownloadUrl(record) {
      if (!record.downloadurl) {
        if (record.makeCode.startsWith('op')) {
          record.downloadurl = await getOpDownloadUrl(record.url, record.fileName);
        } else {
          record.downloadurl = await getDownloadUrl(record.url, record.fileName);
        }
      }
      return record.downloadurl;
    },
    /** 获取文件下载链接
     * @param {*} record
     * @return {*}
     */
    async copyUrl(record) {
      console.error(record.url);
      navigator.clipboard.writeText('https://luyou.oss-cn-hangzhou.aliyuncs.com' + record.url);
      this.$message.success('已复制');
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    columnMap(columnOptions, key) {
      const returnOpt = columnOptions.filter(item => {
        return item.value == key;
      })[0];
      return returnOpt && returnOpt.label;
    },
    /** 遍历素材消耗状态
     * @param {*} columnOptions
     * @param {*} key
     * @return {*}
     */
    columnMapConsumption(columnOptions, key) {
      const returnOpt = columnOptions.filter(item => {
        return item.value == key;
      })[0];
      return returnOpt;
    },
    // 开始上传时间结束时间选择
    onDateUploadRangeChange(val, dateString) {
      this.filterCondition.startUpLoadTime = dateString[0];
      this.filterCondition.endUpLoadTime = dateString[1];
    },
    // 开始投放时间选择
    onDatePlayRangeChange(val, dateString) {
      this.filterCondition.startMaterialLaunchDate = dateString[0];
      this.filterCondition.endMaterialLaunchDate = dateString[1];
    },
    //筛选
    onFilterBtnClick() {
      if (this.isMatch === 'N' && this.filterCondition.mediaChannelList === undefined) {
        return message.error('未匹配素材筛选时,必须选择渠道!');
      }
      this.tablePagination.page = '1';
      this.tableTotal = {};
      this.getMaterialPageListData(null, true, null);
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    onFilterBtnClickAndFlush() {
      if (this.isMatch === 'N' && this.filterCondition.mediaChannelList === undefined) {
        return message.error('未匹配素材筛选时,必须选择渠道!');
      }
      this.tablePagination.page = '1';
      this.tableTotal = {};
      this.getMaterialPageListData(null, true, true);
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    //重置筛选
    onResetBtnClick() {
      this.filterCondition = {
        playTimeValue: [moment().subtract(30, 'days'), moment()],
        startMaterialLaunchDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        endMaterialLaunchDate: moment().format('YYYY-MM-DD'),
        // startMaterialLaunchDate: '',
        // endMaterialLaunchDate: '',
        fileName: '',
      };

      localStorage.videoFilterCondition = JSON.stringify(this.filterCondition);
      this.onFilterBtnClickAndFlush();
    },
    async getProjectList() {
      let res = await getProjectListData({});
      this.projectListOptions =
        res.page.list &&
        res.page.list.map(item => {
          return { label: item.projectName, value: item.projectCode };
        });
    },
    async getMakeListByName() {
      let res = await getMakeListDataByName({ makeName: '' });
      this.materialScriptOptions =
        res.list &&
        res.list.map(item => {
          return { label: item.makeName, value: item.makeCode };
        });
    },
    /**获取列表数据
     * @param {*}
     * @return {*}
     */
    async getMaterialPageListData(data, isGetTotal, readFromDB) {
      if (data?.refresh) {
        this.tableLoading = false;
      }
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      if (Array.isArray(this.tableSorter.orderField) && this.tableSorter.orderType) {
        this.tableSorter.orderField = [];
        this.tableSorter.orderField.push(this.tableSorter.orderType == 'url' ? 'createTime' : this.tableSorter.orderType);
      } else if (this.tableSorter.orderType) {
        this.tableSorter.orderField = [];
        this.tableSorter.orderField.push(this.tableSorter.orderType == 'url' ? 'createTime' : this.tableSorter.orderType);
      } else {
        this.tableSorter.orderField = ['createTime'];
        this.tableSorter.order = 'desc';
      }
      if (this.tableSorter.orderField?.length > 1) {
        this.tableSorter.orderField = unique(this.tableSorter.orderField);
      }
      this.tablePostData = {
        ...this.tablePagination,
        ...this.filterCondition,
        // deptIdList: this.filterCondition.deptIdList?.map(item => item.value) || [],
        useDeptIds: this.filterCondition.useDeptIds?.map(item => item.value) || [],
        userIdList: this.filterCondition.userIdList?.map(item => item.value) || [],
        shootingNames: this.filterCondition.shootingNames?.map(item => item.label) || [],
        screenwriterNames: this.filterCondition.screenwriterNames?.map(item => item.label) || [],
        producerNames: this.filterCondition.producerNames?.map(item => item.label) || [],
        actorName: this.filterCondition.actorName?.value,
        limit: this.tablePagination.limit,
        materialType: 'VIDEO',
        ...this.tableSorter,
        timeline: this.filterCondition.mediaChannelList === 'GDT' || this.filterCondition.mediaChannelList === 'GDT_V3' ? this.activeConfigType : null,
        // materialIdList: this.filterCondition.materialIds.split(' ').split(',').split(';'),
        mustReadFromDB: false,

      };
      this.tablePostData.minCostTotal && (this.tablePostData.minCostTotal *= 1000);
      this.tablePostData.maxCostTotal && (this.tablePostData.maxCostTotal *= 1000);
      // this.tablePostData.costStatus && (this.tablePostData.costStatus = [this.tablePostData.costStatus]);
      if (this.filterCondition.mediaChannelList) {
        this.tablePostData.mediaChannelList = Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList];
      } else {
        this.tablePostData.mediaChannelList = [];
      }
      if (this.tablePostData.projectCodeList) {
        this.tablePostData.projectCodeList = [];
        this.filterCondition.projectCodeList.forEach(p => {
          this.tablePostData.projectCodeList.push(p.value);
        });
      }

      this.getRefreshTime();
      if (this.tablePostData.materialIds) {
        if (this.filterCondition.materialIds.indexOf(' ') > 0) {
          this.tablePostData.materialIdList = this.filterCondition.materialIds.split(' ');
        } else if (this.filterCondition.materialIds.indexOf(',') > 0) {
          this.tablePostData.materialIdList = this.filterCondition.materialIds.split(',');
        } else if (this.filterCondition.materialIds.indexOf(';') > 0) {
          this.tablePostData.materialIdList = this.filterCondition.materialIds.split(';');
        } else {
          this.tablePostData.materialIdList = [this.filterCondition.materialIds];
        }
      }
      this.tablePostData.materialIdList?.forEach(item => {
        item = item - 0;
      });

      if (this.tablePostData.materialIdList && this.tablePostData.materialIdList.length > 0) {
        this.tablePostData = {
          materialIdList: this.tablePostData.materialIdList,
          ...this.tablePagination,
          limit: this.tablePagination.limit,
          pageSize: this.tablePagination.pageSize,
          materialType: 'VIDEO',
          timeline: this.filterCondition.mediaChannelList === 'GDT' || this.filterCondition.mediaChannelList === 'GDT_V3' ? this.activeConfigType : null,
          ...this.tableSorter,
          mediaChannelList: this.filterCondition.mediaChannelList ? (Array.isArray(this.filterCondition.mediaChannelList) ? this.filterCondition.mediaChannelList : [this.filterCondition.mediaChannelList]) : [],
          startMaterialLaunchDate: this.filterCondition.startMaterialLaunchDate || this.filterCondition.playTimeValue[0]?.format('YYYY-MM-DD'),
          endMaterialLaunchDate: this.filterCondition.endMaterialLaunchDate || this.filterCondition.playTimeValue[1]?.format('YYYY-MM-DD'),
          mustReadFromDB: false,
        };
      }

      if (readFromDB) {
        this.tablePostData.mustReadFromDB = readFromDB;
      }
      this.tablePostData.isMatch = this.isMatch;
      let res = await getMaterialPageData(this.tablePostData);
      if (res.code === 0) {
        if (isGetTotal) {
          try {
            this.tableTotal = await getMaterialTotal(this.tablePostData);
            this.tableTotal.map.id = '总计';
            res.page.list?.unshift(_.cloneDeep(this.tableTotal.map));
          } catch (error) {
            console.log(error);
          }
        } else {
          res.page.list?.unshift(_.cloneDeep(this.tableTotal.map));
        }
        // if(res.page.list){
        //   for (var listKey in res.page.list) {
        //     listKey.screenwriterNames = listKey.screenwriterBOList && listKey.screenwriterBOList.map(e => {
        //       return screenwriter_name;
        //     });
        //
        //     listKey.shootingNames = listKey.shootingBOList && listKey.shootingBOList.map(e => {
        //       return shooting_name;
        //     });
        //   }
        // }

        this.materialTableData = res.page.list;
        this.tableSourceData = _.cloneDeep(this.materialTableData);
        if (!this.amountShowDetail) {
          this.formatAmount(this.materialTableData);
        }
        // 清空预览记录
        this.previewRecordList.splice(0, this.previewRecordList.length);
        this.tablePagination.current = res.page.currPage;
        this.tablePagination.pageSize = res.page.pageSize;
        this.tablePagination.total = res.page.totalCount;
      }
      this.$nextTick(() => {
        this.setPagination();
      });
      this.tableLoading = false;
    },
    /** 获取表格总计
     * @param {*}
     * @return {*}
     */
    async getMaterialTotal() {
      this.tableTotal = await getMaterialTotal(this.tablePostData);
      this.tableTotal.map.id = '总计';
      this.materialTableData?.unshift(_.cloneDeep(this.tableTotal.map));
      this.tableSourceData = _.cloneDeep(this.materialTableData);
      if (!this.amountShowDetail) {
        this.formatAmount(this.materialTableData);
      }
      this.tableLoading = false;
    },
    //表格分页
    onTableChange(pagination, filters, sorter) {
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;

      this.tableSorter.orderType = sorter.field;
      this.tableSorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';

      this.selectedRows = [];
      this.selectedRowKeys = [];

      this.getMaterialPageListData();
    },
    //添加素材
    onAddMaterialBtnClick() {
      console.log("是否前测")
      this.materialModalData = {
        materialType: 'VIDEO',
      };
      this.closeModal();
      this.showV2 = true; // 上传视频，默认是前测
      this.showUploadModal2 = true;
      this.$nextTick(() => {
        this.uploadVisible = true;
      });
    },
    //编辑素材
    async onTableUpdateClick(record) {
      this.uploadMode = 'edit';
      let shootingIds = record.shootingBOList != 0 && record.shootingBOList?.map(e => {
        return e.shooting_id;
      });
      let screenwriterIds = record.screenwriterBOList != 0 && record.screenwriterBOList?.map(e => {
        return e.screenwriter_id;
      });

      this.materialModalData = { ...record, shootingIds: shootingIds, screenwriterIds: screenwriterIds };

      console.error('==================编辑素材=======================');

      this.closeModal();
      this.showUploadModal = true;
      this.$nextTick(() => {
        this.uploadVisible = true;
      });
    },
    // 查看素材的详情
    onViewDetailsClick(record) {

      let video = document.getElementById('material_detail_player');
      if (video) {
        video.load();
      }
      if (!record.ossurl) {
        this.getOSSUrl(record);
      }
      this.viewMaterialDrawervisible = true;
      this.$nextTick(() => {
        this.getVideoStyle();
      });
      this.previewRecordList.forEach(item => {
        item.hasOwnProperty('play') && (item.play = false);
      });
      this.viewMaterialTitle = `${record.fileName} - ${record.id} - ${record.postName}`;

      this.materialModalData = record;
      this.materialModalData.shootingIds = record.shootingBOList != 0 && record.shootingBOList?.map(e => {
        return e.shooting_id;
      });
      this.materialModalData.screenwriterIds = record.screenwriterBOList != 0 && record.screenwriterBOList?.map(e => {
        return e.screenwriter_id;
      });
      // this.materialModalData.channel = record.channel && record.channel.split(',');

      console.error('==============// 查看素材的详情====================');
      this.materialDetailsMessage = record;

      this.$nextTick(() => {
        const drawer = document.querySelector('.viewMaterialDetails');
        drawer.addEventListener('click', e => {
          e.stopPropagation();
        });
      });
    },

    /** 预览视频
     * @param {*} fileName 视频名称
     * @return {*}
     */
    async preview(record) {
      if (!record.ossurl && this.isMatch === 'Y') {
        await this.getOSSUrl(record);
      }
      record.showVideo = true;
      this.$nextTick(() => {
        document
          .querySelector('.ant-table-scroll')
          .querySelectorAll('video')
          .forEach(item => item?.remove());
        this.playerOptions = {};
        if (this.isMatch === 'N') {
          record.ossurl = record.url;
        }
        this.previewRecordList.push(record);
        this.previewRecordList.forEach(item => {
          item.play = item.id == record.id;
        });
        this.playerOptions = {
          title: record.fileName,
          src: record.ossurl,
          controlslist: ['nodownload  noplaybackrate  noremoteplayback noremote  footbar'],
          disablePictureInPicture: true,
          width: 160,
          height: 130,
        };
      });
    },
    // 视频预览启停状态变化
    playStatusChange(status, record) {
      if (status && this.viewMaterialDrawervisible && this.$refs.materialInfo?.videoPlayerPlay) {
        let video = document.getElementById('material_detail_player');
        if (video) {
          video.pause();
        }
      }
      if (status) {
        record.play = true;
        // 使得页面始终最多只有一个视频正在播放
        this.previewRecordList.forEach(item => {
          item.id != record.id && (item.play = false);
        });
      } else {
        record.play = false;
      }
    },
    /** 复制文件名
     * @param fileName 要复制的内容
     */
    copyFileName(fileName) {
      navigator.clipboard.writeText(fileName);
      this.$message.success('已复制');
    },
    /** 点击关联账户数量跳转关联详细数据
     * @param {Object}} record 点击的行数据
     * @return {*}
     */
    viewMediaDetails(record) {
      this.onViewDetailsClick(record);
      this.materialDetailsKey = '3';
    },
    /** 关闭弹窗抽屉时停止视频
     * @param {*}
     * @return {*}
     */
    closeModal() {
      const modal = document.querySelector('.ant-modal');
      if (modal && modal?.style.display != 'none') {
        return;
      }
      const drawer = document.querySelector('.viewMaterialDetails');
      if (drawer)
        drawer.removeEventListener('click', e => {
          e.stopPropagation();
        });
      if (this.viewMaterialDrawervisible) {
        this.viewMaterialDrawervisible = false;
        this.viewActorVideosVisible = false;
      }
    },
    // 获取video宽高
    getVideoStyle() {
      const videoContainer = document.getElementsByClassName('img-style')[0];
      if (!videoContainer) {
        return;
      }
      let width = window.getComputedStyle(videoContainer).width;
      this.videoStyle = {
        width: parseFloat(width) - 6 + 'px',
        height: (parseFloat(width) * 9) / 16 + 'px',
      };
    },
    // 生成已筛选条件tag数据
    getFilterOptionsTag() {
      const filterValueDict = {
        materialIds: '素材id',
        fileName: '素材名称',
        materialChannel: '素材类型',
        projectCodeList: '产品',
        deptIdList: '部门',
        userIdList: '上传人员',
        shootingName: '摄像',
        shootingNames: '摄像',
        producerName: '制片',
        producerNames: '制片',
        screenwriterName: '编导',
        screenwriterNames: '编导',
        direction: '素材方向',
        uploadTimeValue: '上传时间',
        useDeptIds: '使用部门',
        mediaChannelList: '媒体渠道',
        costStatus: '投放状态',
        rejectTag: '拒审状态',
        actorName: '演员',
        sitcomType: '情景类别',
        material_properties: '素材标签',
        labelIds: '标签',
      };
      const tagList = [];
      const keys = Object.keys(filterValueDict);
      keys.forEach(key => {
        if (this.filterCondition[key]) {
          let label = filterValueDict[key];
          let value = [];
          if (key == 'rejectTag') {
            value = [this.columnMap(this.rejectTagOptions, this.filterCondition[key])];
          } else if (key == 'uploadTimeValue') {
            let str = this.filterCondition.uploadTimeValue?.join('——');
            str && value.push(str);
          } else if (key == 'materialChannel') {
            value = this.filterCondition.materialChannel.map(item => materialType[item]) || [];
          } else if (Array.isArray(this.filterCondition[key]) && this.filterCondition[key].length) {
            this.filterCondition[key].forEach(item => {
              if (typeof item == 'object') {
                value.push(item.label);
              } else {
                value.push(item);
              }
            });
          } else if (!Array.isArray(this.filterCondition[key])) {
            let val = this.filterCondition[key];
            if (typeof val == 'object') {
              val = val.label;
            } else if (key == 'direction') {
              val = { HORIZONTAL: '横向', VERTICAL: '竖向' }[val];
            } else if (key == 'mediaChannelList') {
              val = this.mediaList.find(item => item.value == val)?.text;
            }
            value.push(val);
          }
          value.length && tagList.push({ label, value, key });
        }
      });
      let label = '消耗区间';
      let value = '';
      if (this.filterCondition.maxCostTotal && this.filterCondition.minCostTotal) {
        value = [`${this.filterCondition.minCostTotal}元 ≤ 消耗 ≤ ${this.filterCondition.maxCostTotal}元`];
      } else if (this.filterCondition.minCostTotal) {
        value = [`${this.filterCondition.minCostTotal}元 ≤ 消耗`];
      } else if (this.filterCondition.maxCostTotal) {
        value = [`消耗 ≤ ${this.filterCondition.maxCostTotal}元`];
      }
      value.length && tagList.push({ label, value, key: 'costRange' });
      return tagList;
    },
    removeTag({ tag }) {
      if (tag.key == 'costRange') {
        this.filterCondition.minCostTotal = undefined;
        this.filterCondition.maxCostTotal = undefined;
      } else if (Array.isArray(this.filterCondition[tag.key])) {
        this.filterCondition[tag.key] = [];
      } else {
        this.filterCondition[tag.key] = null;
      }
      this.getMaterialPageListData(null, true);
      if (!this.filterCondition.mediaChannelList) {
        this.mediaChannelChange();
      }
    },
    ttStatusOptionsEQ(val, material_properties) {
      if (!material_properties) {
        return [];
      }
      let parse = JSON.parse(material_properties);

      var tags = [];
      for (const splitKey in parse) {
        if (val.value == parse[splitKey]) {
          tags[splitKey] = val;
        }
      }
      return tags;
    },
  },
};
</script>
<style lang="less" scoped>
.filter-container {
  :deep(.filter-content) {
    padding-right: 35px;
    margin-left: 0;

    .ant-form-item-label {
      line-height: 33px !important;
    }

    .ant-form-item-control-wrapper.ant-col-18,
    .ant-form-item-control-wrapper.ant-col-19 {
      flex-grow: 1;
      max-width: none;
    }

    .ant-calendar-picker-input {
      height: 32px !important;
    }
  }

  .filter-buttons {
    position: relative;
    font-size: 12px;

    .filter-fold-icon {
      position: absolute;
      top: 4px;
      left: -46px;
      height: 24px;
      border-radius: 2px;
      text-align: center;
      line-height: 24px;
      color: #197afb;
      cursor: pointer;

      :deep(svg) {
        transform: rotateZ(-90deg);
      }

      &.folded :deep(svg) {
        transform: rotateZ(90deg);
      }
    }
  }
}
</style>
<style lang="less">
.material-library {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .table-wrapper {
    height: 200px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    #material-library-table {
      height: 200px;
      flex-grow: 1;
    }

    .media-table {
      .material_title_edit {
        display: none;
        cursor: pointer;
      }

      .material_title:hover .material_title_edit {
        display: inline-block;
      }

      .video-player {
        position: absolute;
        background: #fff;
      }

      &.table-with-statistic {
        .column48 {
          height: 36px;

          .ant-table-header-column {
            height: 20px;

            & > div {
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
        }

        tr {
          th,
          td {
            padding: 8px;
          }
        }

        // 斑马纹
        .table-striped {
          background-color: #fafafa;
        }

        .ant-table-body,
        .ant-table-body-inner {
          padding-bottom: 44px;
        }
      }

      .ant-table-header {
        tr:nth-child(2) > * {
          color: #197afb;
          font-weight: normal;
        }
      }

      // 分页模块
      .ant-table-pagination {
        display: none;
        margin: 10px 0 !important;
        transform: translate(-10px, -50px);
      }
    }
  }
}

.no-flex {
  .ant-form-item-label {
    flex: none;
  }
}

.editScript {
  height: 30px;

  .ant-col {
    height: 30px;

    .ant-form-item-control {
      padding-left: 3rem;
    }
  }
}

.actorList {
  .ant-dropdown-trigger {
    width: 100%;
  }
}

.addUpdateModal {
  .ant-modal-body {
    overflow: auto;

    .ant-form-item {
      margin-bottom: 0.625rem;
    }
  }
}

.inputCost {
  .ant-form-item-control-wrapper {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}

.video-modal {
  width: 640px !important;
  padding: 0;
  background: #fff;

  .ant-modal-body {
    padding: 0;
    position: relative;

    .video-title {
      position: absolute;
      z-index: 10;
      color: #fff;
      margin: 10px;
    }

    .slick-slide {
      height: 360px !important;

      #videoPlayer {
        height: 360px !important;
      }
    }
  }

  .ant-modal-close-x {
    width: 38px;
    height: 38px;
    line-height: 38px;
    color: white;
  }

  .preview-action {
    height: 3em;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 2em;
  }
}

.addUpdateModal {
  .ant-modal-header {
    padding: 16px 40px;
  }
}

.updateMaterialStyle {
  .ant-modal-content {
    min-height: 7.5rem;

    .ant-modal-body {
      padding: 1.41rem 1.41rem 0.75rem 1.41rem;

      .ant-select {
        width: 6.75rem;
      }
    }
  }
}
</style>
